import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { RiCloseFill } from "react-icons/ri";
import { toast } from 'react-toastify';
import { PopupContext } from '../../context/popupContext';
import { attendanceTypeOptions } from '../../utilities/selectOptions';
import { getFilteredAttendances, updateAttendance } from '../../utilities/sendRequest';
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Marker icon setting for leaflet
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
    iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});

const ModalAttendanceDetail = ({ setDatas, data, setShowModalDetail, startTime, endTime, divisionSlug, employee, status }) => {
    const { popupDispatch } = useContext(PopupContext)
    const clickRef = useRef(null)
    const [isReady, setIsReady] = useState(false)
    const [fecthAgain, setFetchAgain] = useState(false)
    const [date, setDate] = useState(data.date)
    const [clockIn, setClockIn] = useState(data.clock_in)
    const [clockOut, setClockOut] = useState(data.clock_out)
    const [type, setType] = useState(data.status)
    const [note, setNote] = useState(data.note)
    const [locationIn, setLoactionIn] = useState(data.location_in)
    const [locationOut, setLoactionOut] = useState(data.location_out)
    
    const [lat, lng] = data.meta ? data.meta.split(", ").map(Number) : ["", ""];
    const [markerPosition, setMarkerPosition] = useState([lat, lng]);
    const [latOut, lngOut] = data.meta_out ? data.meta_out.split(", ").map(Number) : ["", ""];
    const [markerPositionOut, setMarkerPositionOut] = useState([latOut, lngOut]);

    useEffect(() => {
        const isReady = (date !== "" && clockIn !== "" && clockOut !== "" && type !== "");
        setIsReady(isReady);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date, clockIn, clockOut, type])
    
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (clickRef.current && !clickRef.current.contains(event.target)) {
                setShowModalDetail(null)
                popupDispatch({ type: "POPUP_END" });
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clickRef])

    useEffect(() => {
        setFetchAgain(false)
        if(fecthAgain) {
            fetchFilteredAttendance()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fecthAgain])

    const fetchFilteredAttendance = () =>{
        getFilteredAttendances(divisionSlug, status, employee, startTime, endTime).then((res) => {
            setDatas(
                res.payload.sort((p1, p2) => {
                    return new Date(p2.date) - new Date(p1.date)
                })
            )
            
        }).catch((err) => {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        }).finally(() => {
            setShowModalDetail(false)
        })
        popupDispatch({ type: "POPUP_END" });
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsReady(false)

        const reqData = {
            date,
            clock_in: clockIn,
            clock_out: clockOut,
            status: type,
            note
        }

        updateAttendance(data.id, reqData).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        }).catch((error) => {
            toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        }).finally(() => {
            setIsReady(true)
            setFetchAgain(true)
        })
    }

    const handleModal = () => {
        setShowModalDetail(null)
        popupDispatch({ type: "POPUP_END" });
    }

    const LocationMarker = () => {
        useMapEvents({
            click(e) {
                const { lat, lng } = e.latlng;
                setMarkerPosition([lat, lng]);
            },
        });
        return markerPosition ? <Marker position={markerPosition}></Marker> : null;
    }

    const LocationMarkerOut = () => {
        useMapEvents({
            click(e) {
                const { lat, lng } = e.latlng;
                setMarkerPositionOut([lat, lng]);
            },
        });
        return markerPositionOut ? <Marker position={markerPositionOut}></Marker> : null;
    }

    return (
        <div className="absolute top-0 left-0 z-10 p-3 w-screen min-h-screen overscroll-y-none overflow-y-hidden bg-black bg-opacity-70 flex items-center justify-center">
            <div ref={clickRef} className="p-3 w-11/12 lg:w-1/3 md:w-1/2 h-full bg-white rounded-md flex flex-col">
                <div className="px-2 flex justify-between items-center">
                    <h3 className='font-bold font-ssp text-xl md:text-2xl'>Detail Attendance</h3>
                    <RiCloseFill onClick={handleModal} className='w-8 h-8 cursor-pointer'/>
                </div>
                <form onSubmit={handleSubmit} className='mt-5 px-5 w-full flex flex-col'>
                    {/* date */}
                    <div className="relative z-0 mb-3 mt-3 w-full group">
                        <input 
                            value={date} 
                            onChange={(e) => setDate(e.target.value)}
                            type="date" 
                            name="date" 
                            id="date" 
                            className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 
                                        appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                            placeholder=" " 
                            required
                        />
                        <label 
                            htmlFor="date" 
                            className="absolute top-3 -z-10 text-sm text-gray-500 dark:text-gray-400 
                                        duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:font-medium
                                        peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 
                                        peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                        >Tanggal</label>
                    </div>
                    {/* clockIn */}
                    <div className="relative z-0 mb-3 mt-3 w-full group">
                        <input 
                            value={clockIn} 
                            onChange={(e) => setClockIn(e.target.value)}
                            type="time" 
                            name="clockIn" 
                            id="clockIn" 
                            className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 
                                        appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                            placeholder=" " 
                            required
                        />
                        <label 
                            htmlFor="clockIn" 
                            className="absolute top-3 -z-10 text-sm text-gray-500 dark:text-gray-400 
                                        duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:font-medium
                                        peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 
                                        peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                        >Jam Masuk</label>
                    </div>
                    {/* clockOut */}
                    <div className="relative z-0 mb-3 mt-3 w-full group">
                        <input 
                            value={clockOut || ""} 
                            onChange={(e) => setClockOut(e.target.value)}
                            type="time" 
                            name="clockOut" 
                            id="clockOut" 
                            className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 
                                        appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                            placeholder=" " 
                        />
                        <label 
                            htmlFor="clockOut" 
                            className="absolute top-3 -z-10 text-sm text-gray-500 dark:text-gray-400 
                                        duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:font-medium
                                        peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 
                                        peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                        >Jam Pulang</label>
                    </div>
                    {/* employee */}
                    <div className="relative z-0 mb-3 mt-3 w-full group">
                        <input 
                            value={data.User.fullname+' - '+data.User.nip}
                            type="text" 
                            name="employee" 
                            id="employee" 
                            className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 
                                        appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                            placeholder=" " 
                            required readOnly
                        />
                        <label 
                            htmlFor="employee" 
                            className="absolute top-3 -z-10 text-sm text-gray-500 dark:text-gray-400 
                                        duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:font-medium
                                        peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 
                                        peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                        >Employee</label>
                    </div>
                    {/* echelon */}
                    <div className="relative z-0 mb-3 mt-3 w-full group">
                        <input 
                            value={data.User.Biodata.Echelon.title}
                            type="text" 
                            name="echelon" 
                            id="echelon" 
                            className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 
                                        appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                            placeholder=" " 
                            required readOnly
                        />
                        <label 
                            htmlFor="echelon" 
                            className="absolute top-3 -z-10 text-sm text-gray-500 dark:text-gray-400 
                                        duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:font-medium
                                        peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 
                                        peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                        >Eselon</label>
                    </div>
                    {/* office */}
                    <div className="relative z-0 mb-3 mt-3 w-full group">
                        <input 
                            value={data.User.Biodata.Office.name}
                            type="text" 
                            name="office" 
                            id="office" 
                            className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 
                                        appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                            placeholder=" " 
                            required readOnly
                        />
                        <label 
                            htmlFor="office" 
                            className="absolute top-3 -z-10 text-sm text-gray-500 dark:text-gray-400 
                                        duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:font-medium
                                        peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 
                                        peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                        >Office</label>
                    </div>
                    {/* note */}
                    <div className="relative z-0 mb-3 mt-3 w-full group">
                        <input 
                            value={note || ""} 
                            onChange={(e) => setNote(e.target.value)}
                            type="text" 
                            name="note" 
                            id="note" 
                            className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 
                                        appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                            placeholder=" " 
                        />
                        <label 
                            htmlFor="note" 
                            className="absolute top-3 -z-10 text-sm text-gray-500 dark:text-gray-400 
                                        duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:font-medium
                                        peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 
                                        peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                        >Note</label>
                    </div>
                    {/* type */}
                    <div className="mt-3 mb-6 w-full relative z-0 group">
                        <select 
                            required 
                            value={type} 
                            onChange={(e) => setType(e.target.value)} 
                            id="type" 
                            className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent 
                                        border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                        focus:border-blue-600 peer"
                        >
                            {attendanceTypeOptions.map((option) => (
                                <option key={option.value} value={option.value} >{option.label}</option>
                            ))}
                        </select>
                        <label 
                            htmlFor="type" 
                            className="absolute top-3 -z-10 text-sm text-gray-500 
                                        duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                        peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                        peer-focus:scale-75 peer-focus:-translate-y-6"
                        >Type</label>
                    </div>
                    <div className="relative z-0 mb-3 mt-3 w-full group">
                        <input 
                            value={locationIn}
                            type="text" 
                            name="location" 
                            onChange={(e) => setLoactionIn(e.target.value)} 
                            id="location" 
                            className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 
                                        appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                            placeholder=" " 
                            required readOnly
                        />
                        <label 
                            htmlFor="location" 
                            className="absolute top-3 -z-10 text-sm text-gray-500 dark:text-gray-400 
                                        duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:font-medium
                                        peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 
                                        peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                        >Lokasi Masuk</label>
                    </div>
                    {/* map in */}
                    {data.meta
                        ?
                            <div className="mt-3 mb-6 w-full relative z-0 group" style={{ height: '400px' }}>
                                <small className='text-sm text-gray-500'>Map Clock In</small>
                                <MapContainer center={markerPosition} position={markerPosition} zoom={20} style={{ height: "100%", width: "100%" }}>
                                    <TileLayer
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                        // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                    />
                                    <LocationMarker />
                                </MapContainer>
                            </div>
                        : 
                        <div className="mt-3 mb-6 w-full relative z-0 group font-ssp italic">
                            Maps tidak tersedia
                        </div>
                    }
                    
                    <div className="relative z-0 mb-3 mt-14 w-full group">
                        <input 
                            value={locationOut}
                            type="text" 
                            name="location" 
                            onChange={(e) => setLoactionOut(e.target.value)} 
                            id="location" 
                            className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 
                                        appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                            placeholder=" " 
                            required readOnly
                        />
                        <label 
                            htmlFor="location" 
                            className="absolute top-3 -z-10 text-sm text-gray-500 dark:text-gray-400 
                                        duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:font-medium
                                        peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 
                                        peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                        >Lokasi Pulang</label>
                    </div>
                    {/* map out */}
                    {data.meta_out
                        ?
                            <div className="mt-3 mb-6 w-full relative z-0 group" style={{ height: '400px' }}>
                                <small className='text-sm text-gray-500'>Map Clock Out</small>
                                <MapContainer center={markerPositionOut} position={markerPositionOut} zoom={20} style={{ height: "100%", width: "100%" }}>
                                    <TileLayer
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                        // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                    />
                                    <LocationMarkerOut />
                                </MapContainer>
                            </div>
                        : 
                        <div className="mt-3 mb-6 w-full relative z-0 group font-ssp italic">
                            Maps tidak tersedia
                        </div>
                    }
                        
                    {/* Button */}
                    <div className="mb-6 mt-5 w-full relative z-0 flex gap-3 justify-end">
                        <button type="submit" 
                            disabled={!isReady}
                            className={`mt-5 px-5 py-2.5 w-full sm:w-auto ${isReady ? 'text-white bg-blue-400 hover:bg-blue-500 cursor-pointer' : 'text-gray-800 bg-gray-300 cursor-wait'} rounded-lg text-sm
                                        text-center font-medium`}
                        >Submit</button>
                        <button onClick={handleModal} 
                            className="px-5 py-2.5 mt-5 w-full sm:w-auto bg-red-400 hover:bg-red-500 rounded-lg text-sm text-white
                                        text-center font-medium cursor-pointer"
                        >Close</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

ModalAttendanceDetail.propTypes = {
    data: PropTypes.object,
    setDatas: PropTypes.func,
    setShowModalDetail: PropTypes.func,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    divisionSlug: PropTypes.string,
    status: PropTypes.string,
    employee: PropTypes.string,
}

export default ModalAttendanceDetail