import React, { useEffect, useState, useContext } from 'react';
import { toast } from 'react-toastify';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { AuthContext } from '../../context/authContext';
import { getLogPoints, logoutUser } from '../../utilities/sendRequest';
import { dateTimeIndo } from '../../utilities/helper';
import { PopupContext } from '../../context/popupContext';
import ModalPointDetail from '../../components/LogPoint/modalPoint';

const LogPoints = () => {
    const { dispatch } = useContext(AuthContext)
    const { popupDispatch } = useContext(PopupContext)
    const [datas, setDatas] = useState([])
    const [data, setData] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [fetchMore, setFetchMore] = useState(true)

    useEffect(() => {
        fetchLogs()
        setFetchMore(false)
        
        // eslint-disable-next-line
    }, [fetchMore])

    const fetchLogs = async () => {
        getLogPoints().then((res) => {
            setDatas(res.payload.sort((p1, p2) => {
                return new Date(p2.createdAt) - new Date(p1.createdAt)
            }))
        }).catch((error) => {
            toast.error(error.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });

            if (error.response.status === 401) {
                setTimeout(() => {
                    logoutUser(dispatch)
                }, 3500)
            }
        })
    }

    const columns = [
        { 
            field: 'createdAt', 
            headerName: 'Time', 
            width: 150, 
            renderCell: (params) => {
                return (
                    <div className="font-ssp">
                        {dateTimeIndo(params.row.createdAt)}
                    </div>
                )
            } 
        },
        { 
            field: 'Obtained_Point_Log', 
            headerName: 'Employee',
            width: 300, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {params.row.Obtained_Point_Log?.fullname+' - '+params.row.Obtained_Point_Log?.nip}
                    </div>
                )
            } 
        },
        { 
            field: 'Approved_Point_Log', 
            headerName: 'Admin',
            width: 250, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {params.row.Approved_Point_Log?.fullname}
                    </div>
                )
            } 
        },
        {
            field: 'type',
            headerName: 'Type',
            width: 150,
            renderCell: (params) => {
                return (
                    <div className="font-ssp">
                        {params.row.type === 'revenue'
                            ? <span className="px-2 py-1 bg-green-400 rounded-full text-white uppercase">PENAMBAHAN</span>
                            : <span className="px-2 py-1 bg-red-400 rounded-full text-white uppercase">PENGURANGAN</span>
                        }
                    </div>
                )
            } 
        },
        { 
            field: 'point', 
            headerName: 'Point',
            width: 200, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {params.row.point}
                    </div>
                )
            } 
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 150,
            renderCell: (params) => {
                return (
                    <button
                        onClick={() => handleModal(params.row)}
                        className='w-full flex flex-row gap-3 items-center'>
                        <VisibilityIcon fontSize='medium' className="text-slate-400 hover:text-slate-500 cursor-pointer"/>
                    </button>
                )
            }
        },
    ]

    const handleModal = (data) => {
        setShowModal(true)
        setData(data)
        popupDispatch({ type: "POPUP_START" });
    }

    return (
        <>
            {showModal && 
                <ModalPointDetail setShowModal={setShowModal} data={data} setFetchMore={setFetchMore} />
            }
            <div className={`min-h-[calc(100vh-4rem)] flex flex-col ${showModal && 'fixed min-w-[calc(100vw-16.666667%)]'} `}>
                {/* header  */}
                <div className="my-5 mb-5 mx-7 md:mx-5 flex flex-col md:flex-row justify-between">
                    <h1 className='font-ssp font-bold text-2xl md:text-3xl'>Log Points</h1>
                </div>
                {/* content  */}
                <div className="my-5 mb-5 mx-7 md:mx-5 flex">
                    <div className="p-2 w-full flex bg-white drop-shadow-lg rounded-md">
                        <DataGrid
                            autoHeight {...datas}
                            rows={datas}
                            columns={columns}
                            pageSize={10}
                            getRowId={(row) => row.id}
                            rowsPerPageOptions={[10]}
                            checkboxSelection
                            disableSelectionOnClick
                            components={{ Toolbar: GridToolbar, }}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default LogPoints