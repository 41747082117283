import React, { useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { RiCloseFill } from "react-icons/ri";
import { getCities, getDistricts, getProvinces, getStreet, getVillages, updateOfficeAddress } from '../../utilities/sendRequest';
import { PopupContext } from '../../context/popupContext';
import { mainOptions } from '../../utilities/selectOptions';
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Marker icon setting for leaflet
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
    iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});

const ModalOfficeAddressDetail = ({ setShowModalAddress, modalData, setFetchAgain }) => {
    const { popupDispatch } = useContext(PopupContext)
    const clickRef = useRef(null)
    const [isReady, setIsReady] = useState(false)

    const [typingTimeout, setTypingTimeout] = useState(null);
    const [latitude, longitude] = modalData.Address.meta.split(',').map(coord => parseFloat(coord));
    const [lat, setLat] = useState(latitude ? latitude : ''); 
    const [lng, setLng] = useState(longitude ? longitude : ''); 
    const [markerPosition, setMarkerPosition] = useState(
        !isNaN(latitude) && !isNaN(longitude) ? [latitude, longitude] : [lat, lng]
    );
    
    const [name, setName] = useState(modalData.Address.name)
    const [postal, setPostal] = useState(modalData.Address.postal_code)
    const [province, setProvince] = useState(modalData.Address.Indonesia_Village.Indonesia_District.Indonesia_City.Indonesia_Province.id)
    const [city, setCity] = useState(modalData.Address.Indonesia_Village.Indonesia_District.Indonesia_City.id)
    const [district, setDistrict] = useState(modalData.Address.Indonesia_Village.Indonesia_District.id)
    const [village, setVillage] = useState(modalData.Address.Indonesia_Village.id)
    const [provinces, setProvinces] = useState([])
    const [cities, setCities] = useState([])
    const [districts, setDistricts] = useState([])
    const [villages, setVillages] = useState([])
    const [status, setStatus] = useState(modalData.Address.is_main)
    
    useEffect(() => {
        const isReady = (name !== "" && status !== "" && postal !== "" && province !== "" && city !== "" && district !== "" && village !== "" && villages.length !== 0);
        setIsReady(isReady);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name, status, postal, province, city, district, village, villages])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (clickRef.current && !clickRef.current.contains(event.target)) {
                setShowModalAddress(null)
                popupDispatch({ type: "POPUP_END" });
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clickRef])

    useEffect(() => {
        fetchProvinces();
        fetchCities(modalData.Address.Indonesia_Village.Indonesia_District.Indonesia_City.Indonesia_Province.id);
        fetchDistricts(modalData.Address.Indonesia_Village.Indonesia_District.Indonesia_City.id);
        fetchVillages(modalData.Address.Indonesia_Village.Indonesia_District.id)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (isNaN(latitude) || isNaN(longitude)) {
            getStreet(name)
                .then((res) => {
                    if (res.length > 0) {
                        const location = res[0];
                        const latitude = parseFloat(location.lat);
                        const longitude = parseFloat(location.lon);
    
                        // Update lat, lng, dan markerPosition
                        setLat(latitude);
                        setLng(longitude);
                        setMarkerPosition([latitude, longitude]);
                    } else {
                        console.log("Location not found based on address name!");
                        
                        // toast.error('Location not found based on address name!', {
                        //     position: toast.POSITION.TOP_RIGHT,
                        //     autoClose: 3000,
                        // });
                    }
                })
                .catch((err) => {
                    console.log("Error retrieving location from address name.");
                        
                    // toast.error('Error retrieving location from address name.', {
                    //     position: toast.POSITION.TOP_RIGHT,
                    //     autoClose: 3000,
                    // });
                });
        }
    }, [latitude, longitude, name]);

    const handleProvince = (value) => {
        setProvince(value)
        setCities([])
        fetchCities(value);
        setCity("")
        setVillage("")
        setDistricts([])
        setVillages([])
    }

    const handleCity = (value) => {
        setCity(value)
        setDistricts([])
        fetchDistricts(value);
        setDistrict("")
        setVillages([])
    }

    const handleDistrict = (value) => {
        setDistrict("")
        setDistrict(value)
        fetchVillages(value);
        setVillage("")
    }

    const fetchProvinces = async () => {
        getProvinces().then((res) => {
            setProvinces(res.payload)
        }).catch((err) => {
            toast.error(err, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })  
    }

    const fetchCities = async (paramsProvince) => {
        getCities(paramsProvince).then((res) => {
            setCities(res.payload)
        }).catch((err) => {
            toast.error(err, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })  
    };

    const fetchDistricts = async (paramsCity) => {
        getDistricts(paramsCity).then((res) => {
            setDistricts(res.payload)
        }).catch((err) => {
            toast.error(err, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })  
    };

    const fetchVillages = async (paramsDistrict) => {
        getVillages(paramsDistrict).then((res) => {
            setVillages(res.payload)
        }).catch((err) => {
            toast.error(err, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })  
    };

    const handleSubmit = async (e) => {
        e.preventDefault()

        setIsReady(false)
        const reqData = {
            name,
            postal_code: postal,
            village_id: village,
            is_main: status,
            meta: markerPosition.toString()
        }

        updateOfficeAddress(modalData.id, reqData).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        }).catch((err) => {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        }).finally(() => {
            setIsReady(true)
            setFetchAgain(true)
            setShowModalAddress(false)
        })
        popupDispatch({ type: "POPUP_END" });
    }

    const handleModal = () => {
        setShowModalAddress(null)
        popupDispatch({ type: "POPUP_END" });
    }

    const LocationMarker = () => {
        useMapEvents({
            click(e) {
                const { lat, lng } = e.latlng;
                setLat(lat);
                setLng(lng);
                setMarkerPosition([lat, lng]);
            },
        });
        return markerPosition ? <Marker position={markerPosition}></Marker> : null;
    }

    const handleAddressChange = (e) => {
        const inputValue = e.target.value;
        setName(inputValue); 

        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }
      
        setTypingTimeout(setTimeout(() => {
            getStreet(inputValue)
                .then((res) => {
                    if (res.length > 0) {
                        const location = res[0];
                        const latitude = parseFloat(location.lat);
                        const longitude = parseFloat(location.lon);

                        setLat(latitude);
                        setLng(longitude);
                        setMarkerPosition([latitude, longitude]);
                    } else {
                        toast.error('Location not found!', {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });
                    }
                })
                .catch((err) => {
                    toast.error(err, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000,
                    });
                });
            }, 500)
        );
    };

    return (
        <div className="absolute top-0 left-0 z-10 p-3 w-screen min-h-screen overscroll-y-none overflow-y-hidden bg-black bg-opacity-70 flex items-center justify-center">
            <div ref={clickRef} className="p-3 w-11/12 lg:w-1/3 md:w-1/2 h-full bg-white rounded-md flex flex-col">
                <div className="px-2 flex justify-between items-center">
                    <h3 className='font-bold font-ssp text-xl md:text-2xl'>Detail Address Office</h3>
                    <RiCloseFill onClick={handleModal} className='w-8 h-8 cursor-pointer'/>
                </div>
                <form onSubmit={handleSubmit} className='mt-5 px-4 w-full flex flex-col'>
                    {/* address */}
                    <div className="mt-3 mb-6 w-full relative z-0 group">
                        <input 
                            value={name} 
                            // onChange={(e) => setName(e.target.value)} 
                            onChange={handleAddressChange}
                            type="text" 
                            name="name"
                            id="name" 
                            className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                        border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                        focus:border-blue-600 peer" 
                            placeholder=" " 
                            required 
                        />
                        <label 
                            htmlFor="name" 
                            className="absolute top-3 -z-10 text-sm text-gray-500 
                                        duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                        peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                        peer-focus:scale-75 peer-focus:-translate-y-6"
                        >Address</label>
                    </div>
                    {/* postal */}
                    <div className="mt-3 mb-6 w-full relative z-0 group">
                        <input 
                            value={postal} 
                            onChange={(e) => setPostal(e.target.value)} 
                            type="text" 
                            name="postal"
                            id="postal" 
                            className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                        border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                        focus:border-blue-600 peer" 
                            placeholder=" "  
                        />
                        <label 
                            htmlFor="postal" 
                            className="absolute top-3 -z-10 text-sm text-gray-500 
                                        duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                        peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                        peer-focus:scale-75 peer-focus:-translate-y-6"
                        >Postal Code</label>
                    </div>
                    {/* provinces */}
                    <div className="mt-3 mb-6 w-full relative z-0 group">
                        <select 
                            required 
                            value={province} 
                            onChange={(e) => handleProvince(e.target.value)} 
                            // onChange={(e) => setProvince(e.target.value)} 
                            id="province" 
                            className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent 
                                        border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                        focus:border-blue-600 peer"
                        >
                            <option value="">Choose a Province</option>
                            {provinces.map((option) => (
                                <option key={option.id} value={option.id}>{option.name}</option>
                            ))}
                        </select>
                        <label 
                            htmlFor="province" 
                            className="absolute top-3 -z-10 text-sm text-gray-500 
                                        duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                        peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                        peer-focus:scale-75 peer-focus:-translate-y-6"
                        >Province</label>
                    </div>
                    {/* city */}
                    <div className="mt-3 mb-6 w-full relative z-0 group">
                        <select 
                            required 
                            value={city} 
                            onChange={(e) => handleCity(e.target.value)} 
                            // onChange={(e) => setCity(e.target.value)} 
                            id="city" 
                            className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent 
                                        border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                        focus:border-blue-600 peer"
                        >
                            <option value="">Choose a City</option>
                            {cities.map((option) => (
                                <option key={option.id} value={option.id}>{option.name}</option>
                            ))}
                        </select>
                        <label 
                            htmlFor="city" 
                            className="absolute top-3 -z-10 text-sm text-gray-500 
                                        duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                        peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                        peer-focus:scale-75 peer-focus:-translate-y-6"
                        >City</label>
                    </div>
                    {/* district */}
                    <div className="mt-3 mb-6 w-full relative z-0 group">
                        <select 
                            required 
                            value={district} 
                            // onChange={(e) => setDistrict(e.target.value)} 
                            onChange={(e) => handleDistrict(e.target.value)} 
                            id="district" 
                            className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent 
                                        border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                        focus:border-blue-600 peer"
                        >
                            <option value="">Choose a District</option>
                            {districts.map((option) => (
                                <option key={option.id} value={option.id}>{option.name}</option>
                            ))}
                        </select>
                        <label 
                            htmlFor="district" 
                            className="absolute top-3 -z-10 text-sm text-gray-500 
                                        duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                        peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                        peer-focus:scale-75 peer-focus:-translate-y-6"
                        >District</label>
                    </div>
                    {/* village */}
                    <div className="mt-3 mb-6 w-full relative z-0 group">
                        <select 
                            required 
                            value={village} 
                            onChange={(e) => setVillage(e.target.value)} 
                            id="village" 
                            className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent 
                                        border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                        focus:border-blue-600 peer"
                        >
                            <option value="">Choose a Village</option>
                            {villages.map((option) => (
                                <option key={option.id} value={option.id}>{option.name}</option>
                            ))}
                        </select>
                        <label 
                            htmlFor="village" 
                            className="absolute top-3 -z-10 text-sm text-gray-500 
                                        duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                        peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                        peer-focus:scale-75 peer-focus:-translate-y-6"
                        >Village</label>
                    </div>
                    {/* status */}
                    <div className="mt-3 mb-6 w-full relative z-0 group">
                        <select 
                            required 
                            value={status} 
                            onChange={(e) => setStatus(e.target.value)} 
                            id="status" 
                            className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent 
                                        border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                        focus:border-blue-600 peer"
                        >
                            {mainOptions.map((option) => (
                                <option key={option.value} value={option.value}>{option.label}</option>
                            ))}
                        </select>
                        <label 
                            htmlFor="status" 
                            className="absolute top-3 -z-10 text-sm text-gray-500 
                                        duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                        peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                        peer-focus:scale-75 peer-focus:-translate-y-6"
                        >Status</label>
                    </div>
                    {/* map */}
                    <div className="mt-3 mb-6 w-full relative z-0 group" style={{ height: '400px' }}>
                        <MapContainer center={markerPosition} position={markerPosition} zoom={5} style={{ height: "100%", width: "100%" }}>
                            <TileLayer
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            />
                            <LocationMarker />
                        </MapContainer>
                    </div>
                    {/* Button */}
                    <div className="mb-6 mt-5 w-full relative z-0 flex gap-3 justify-end">
                        <button type="submit" 
                            disabled={!isReady}
                            className={`mt-5 px-5 py-2.5 w-full sm:w-auto ${isReady ? 'text-white bg-blue-400 hover:bg-blue-500 cursor-pointer' : 'text-gray-800 bg-gray-300 cursor-wait'} rounded-lg text-sm
                                        text-center font-medium`}
                        >Save</button>
                        <button onClick={handleModal} 
                            className="px-5 py-2.5 mt-5 w-full sm:w-auto bg-red-400 hover:bg-red-500 rounded-lg text-sm text-white
                                        text-center font-medium cursor-pointer"
                        >Close</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

ModalOfficeAddressDetail.propTypes = {
    modalData: PropTypes.object,
    setShowModalAddress: PropTypes.func,
    setFetchAgain: PropTypes.func,
}

export default ModalOfficeAddressDetail