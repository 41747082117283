import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import { getOffice, updateOffices } from '../../utilities/sendRequest';
import { statusOptions } from '../../utilities/selectOptions';
import { createSlug } from '../../utilities/helper';
import CardOfficePhones from '../../components/Offices/cardPhones';
import ModalOfficePhoneDetail from '../../components/Offices/modalPhone';
import CardOfficeAddress from '../../components/Offices/cardAddress';
import ModalOfficeAddressDetail from '../../components/Offices/modalAddres';


const DetailOffice = () => {
    const params = useParams()
    const navigate = useNavigate()
    const [showModalPhone, setShowModalPhone] = useState(false)
    const [showModalAddress, setShowModalAddress] = useState(false)

    const [fetchAgain, setFetchAgain] = useState(false)
    const [modalData, setModalData] = useState([])
    const [data, setData] = useState({})
    const [isReady, setIsReady] = useState(false)
    const [fetchMore, setFetchMore] = useState(true)
    const [name, setName] = useState("")
    const [status, setStatus] = useState("")

    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [convertedContent, setConvertedContent] = useState(null);
    
    useEffect(() => {
        fetchOffice()
        setFetchMore(false)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchMore])

    useEffect(() => {
        let html = convertToHTML(editorState.getCurrentContent());
        setConvertedContent(html);

    }, [editorState]);

    useEffect(() => {
        const isReady = (name !== "" && status !== "");
        setIsReady(isReady);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name, status])

    const fetchOffice = async () => {
        getOffice(params.slug).then((res) => {
            setData(res.payload);
            
            setName(res.payload.name)
            setStatus(res.payload.is_active)
            setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(res.payload.description))))
        }).catch((err) => {
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })  
    }

    const handleUpdate = async (e) => {
        e.preventDefault()

        setIsReady(false)
        
        const reqData = {
            name,
            slug: createSlug(name),
            description: convertedContent,
            is_active: status
        }
        updateOffices(data.slug, reqData).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        }).catch((err) => {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        }).finally(() => {
            setIsReady(true)
            navigate(`/offices/${createSlug(name)}`)
            setFetchMore(true)
        })
    }


    return (
        <>
            {showModalPhone && 
                <ModalOfficePhoneDetail setShowModalPhone={setShowModalPhone} modalData={modalData} setFetchAgain={setFetchAgain} />
            }
            {showModalAddress && 
                <ModalOfficeAddressDetail setShowModalAddress={setShowModalAddress} modalData={modalData} setFetchAgain={setFetchAgain} />
            }
            <div className={`min-h-[calc(100vh-4rem)] flex flex-col ${(showModalPhone || showModalAddress) && 'fixed min-w-[calc(100vw-16.666667%)]'} `}>
                {/* header */}
                <div className="my-5 mx-7 md:mx-5 flex flex-row flex-wrap gap-5 justify-between">
                    <h1 className='font-ssp font-bold text-4xl md:text-3xl'>Detail Office | {data.name}</h1>
                </div>
                {/* content */}
                <div className="my-5 mx-7 md:mx-5 flex flex-col gap-10">
                    {/* form update */}
                    <div className="px-2 py-4 w-full flex bg-white drop-shadow-lg rounded-md">
                        <form onSubmit={handleUpdate} className='px-5 py-3 w-full flex flex-wrap justify-between'>
                            {/* name */}
                            <div className="relative z-0 mb-6 w-full md:w-[40%] group">
                                <input 
                                    minLength={3} 
                                    value={name} 
                                    onChange={(e) => setName(e.target.value)} 
                                    type="text" 
                                    name="name" 
                                    id="name" 
                                    className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                                border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                                focus:border-blue-600 peer" 
                                    placeholder=" " 
                                    required 
                                />
                                <label 
                                    htmlFor="name" 
                                    className="absolute top-3 -z-10 text-sm text-gray-500 
                                                duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                                peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                                peer-focus:scale-75 peer-focus:-translate-y-6"
                                >Name</label>
                            </div>
                            {/* status */}
                            <div className="relative z-0 mb-6 w-full md:w-[40%] group">
                                <select required value={status} 
                                    onChange={(e) => setStatus(e.target.value)} 
                                    id="status_select" 
                                    className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                >
                                    {statusOptions.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                                <label 
                                    htmlFor="status_select" 
                                    className="absolute top-3 -z-10 text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                >Status</label>
                            </div>
                            {/* photo */}
                            <div className="mb-6 mt-3 w-full relative z-0 group">
                                <label 
                                    htmlFor="description" 
                                    className="text-sm text-gray-500 duration-300 transform -translate-y-6 
                                                scale-75 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:font-medium 
                                                peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                >Description</label>
                                <Editor
                                    editorState={editorState}
                                    onEditorStateChange={setEditorState}
                                    wrapperClassName="mt-2 border h-[500px] md:h-[300px] relative flex flex-col overflow-hidden border border-gray-300"
                                    toolbarClassName="sticky"
                                    editorClassName="px-2 overflow-y-scroll"
                                />
                            </div>
                            {/* button */}
                            <div className="mb-6 w-full relative z-0 flex justify-center gap-5">
                                <button 
                                    type="submit" 
                                    disabled={!isReady}
                                    className={`mt-5 px-5 py-2.5 w-fit rounded-lg sm:w-autorounded-lg font-medium text-sm text-center ${isReady ? 'text-white bg-green-400 hover:bg-green-500 cursor-pointer' : 'text-gray-800 bg-gray-300 cursor-wait'}`}
                                >Update</button>
                                <Link 
                                    to={'/offices'} 
                                    className="px-5 py-2.5 mt-5 w-full sm:w-auto bg-red-400 hover:bg-red-500 rounded-lg
                                                font-medium text-sm text-white text-center cursor-pointer"
                                >Back</Link>
                            </div>
                        </form>
                    </div>
                    {/* phones */}
                    <CardOfficePhones slug={params.slug} setModalData={setModalData} setShowModalPhone={setShowModalPhone} fetchAgain={fetchAgain} setFetchAgain={setFetchAgain} />
                    {/* address */}
                    <CardOfficeAddress slug={params.slug} setModalData={setModalData} setShowModalAddress={setShowModalAddress} fetchAgain={fetchAgain} setFetchAgain={setFetchAgain} />
                </div>
            </div>
        </>
    )
}

export default DetailOffice