import axios from 'axios';
import { decodeToken } from "react-jwt";
let tokenStorage = localStorage.getItem('EcYhC');

//authentication
export const loginUser = async (userCredentials, dispatch) => {
    dispatch({ type: "LOGIN_START"})
    try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL_SERVER}/users/login`, userCredentials)
        const myDecodedToken = decodeToken(response.data.payload.accessToken);
        
        const userData = { 
            nip: myDecodedToken.nip,
            fullname: myDecodedToken.fullname, 
            email: myDecodedToken.email, 
            photo: myDecodedToken.photo,
            is_admin: myDecodedToken.is_admin,
            exp: myDecodedToken.exp, 
        }
        
        localStorage.setItem('EcYhC', response.data.payload.accessToken);
        tokenStorage = response.data.payload.accessToken
        dispatch({ type: "LOGIN_SUCCESS", payload: userData });
        
    } catch (error) {
        dispatch({ type: "LOGIN_FAILURE", payload: error.response.data})
    }
}

export const logoutUser = async (dispatch) => {
    localStorage.clear();
    dispatch({ type: "LOGOUT_SUCCESS" });
} 


export const getDashboardLeaderboard = async (token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/bec/dashboard.php`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}


//interceptors
const axiosJWT = axios.create();

axiosJWT.interceptors.request.use( async (config) => {
    if (!localStorage.getItem('EcYhC')) return Promise.reject("Invalid token");
    tokenStorage = localStorage.getItem('EcYhC');
    return config;
}, (error) => {
    return Promise.reject(error);
})


// Office
export const getOffices = async () => {
    const { data } = await axiosJWT.get(`${process.env.REACT_APP_BASE_URL_SERVER}/offices`, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const addOffices = async (reqData) => {
    const { data } = await axiosJWT.post(`${process.env.REACT_APP_BASE_URL_SERVER}/offices`, reqData, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const getOffice = async (paramsSlug) => {
    const { data } = await axiosJWT.get(`${process.env.REACT_APP_BASE_URL_SERVER}/offices/${paramsSlug}`, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const toggleOffices = async (paramsSlug) => {
    const { data } = await axiosJWT.put(`${process.env.REACT_APP_BASE_URL_SERVER}/offices/toggle/${paramsSlug}`, "", {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const updateOffices = async (paramsSlug, reqData) => {
    const { data } = await axiosJWT.put(`${process.env.REACT_APP_BASE_URL_SERVER}/offices/${paramsSlug}`, reqData, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

// Information
export const getInformations = async () => {
    const { data } = await axiosJWT.get(`${process.env.REACT_APP_BASE_URL_SERVER}/informations`, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const addInformation = async (reqData) => {
    const { data } = await axiosJWT.post(`${process.env.REACT_APP_BASE_URL_SERVER}/informations`, reqData, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const getInformation = async (paramsID) => {
    const { data } = await axiosJWT.get(`${process.env.REACT_APP_BASE_URL_SERVER}/informations/${paramsID}`, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const updateInformation = async (paramsID, reqData) => {
    const { data } = await axiosJWT.put(`${process.env.REACT_APP_BASE_URL_SERVER}/informations/${paramsID}`, reqData, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const deleteInformation = async (paramsID) => {
    const { data } = await axios.delete(`${process.env.REACT_APP_BASE_URL_SERVER}/informations/${paramsID}`, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

// Employee Status
export const getEmployeeStatus = async () => {
    const { data } = await axiosJWT.get(`${process.env.REACT_APP_BASE_URL_SERVER}/employment_statuses`, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const addEmployeeStatus = async (reqData) => {
    const { data } = await axiosJWT.post(`${process.env.REACT_APP_BASE_URL_SERVER}/employment_statuses`, reqData, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const toggleEmployeeStatus = async (paramsID) => {
    const { data } = await axiosJWT.put(`${process.env.REACT_APP_BASE_URL_SERVER}/employment_statuses/toggle/${paramsID}`, "", {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const updateEmployeeStatus = async (paramsID, reqData) => {
    const { data } = await axiosJWT.put(`${process.env.REACT_APP_BASE_URL_SERVER}/employment_statuses/${paramsID}`, reqData, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

// Posts
export const getPosts = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL_SERVER}/posts`, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const addPost = async (reqData) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL_SERVER}/posts`, reqData, {
        headers:{
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const getPost = async (paramsSlug) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL_SERVER}/posts/${paramsSlug}`, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const togglePost = async (paramsSlug) => {
    const { data } = await axios.put(`${process.env.REACT_APP_BASE_URL_SERVER}/posts/toggle/${paramsSlug}`, "", {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const updatePost = async (paramsSlug, reqData) => {
    const { data } = await axios.put(`${process.env.REACT_APP_BASE_URL_SERVER}/posts/${paramsSlug}`, reqData, {
        headers:{
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

// Post Events
export const createPostEvent = async (paramsSlug, reqData) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL_SERVER}/events/${paramsSlug}`, reqData, {
        headers:{
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const getPostEvents = async (paramsSlug) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL_SERVER}/events/${paramsSlug}`, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const togglePostEvent = async (paramsID) => {
    const { data } = await axios.put(`${process.env.REACT_APP_BASE_URL_SERVER}/events/toggle/${paramsID}`, "", {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const updatePostEvent = async (paramsID, reqData) => {
    const { data } = await axios.put(`${process.env.REACT_APP_BASE_URL_SERVER}/events/${paramsID}`, reqData, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}


// Post Galleries
export const addPostGalleries = async (paramsSlug, reqData) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL_SERVER}/post_galleries/${paramsSlug}`, reqData, {
        headers:{
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const getPostGalleries = async (paramsSlug) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL_SERVER}/post_galleries/${paramsSlug}`, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const updatePostGallery = async (paramsID, reqData) => {
    const { data } = await axios.put(`${process.env.REACT_APP_BASE_URL_SERVER}/post_galleries/${paramsID}`, reqData, {
        headers:{
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const deletePostGallery = async (paramsID) => {
    const { data } = await axios.delete(`${process.env.REACT_APP_BASE_URL_SERVER}/post_galleries/${paramsID}`, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}


// Echelons
export const getEchelons = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL_SERVER}/echelons`, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const addEchelon = async (reqData) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL_SERVER}/echelons`, reqData, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const getEchelon = async (paramsCode) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL_SERVER}/echelons/${paramsCode}`, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const updateEchelon = async (paramsCode, reqData) => {
    const { data } = await axios.put(`${process.env.REACT_APP_BASE_URL_SERVER}/echelons/${paramsCode}`, reqData, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}


// Positions
export const getPositions = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL_SERVER}/positions`, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const addPosition = async (reqData) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL_SERVER}/positions`, reqData, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const getPosition = async (paramsSlug) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL_SERVER}/positions/${paramsSlug}`, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const updatePosition = async (paramsSlug, reqData) => {
    const { data } = await axios.put(`${process.env.REACT_APP_BASE_URL_SERVER}/positions/${paramsSlug}`, reqData, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}


// Employee
export const getEmployees = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL_SERVER}/users/employees`, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const addEmployee = async (reqData) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL_SERVER}/users/register`, reqData, {
        headers:{
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const toggleEmployee = async (paramsNip) => {
    const { data } = await axios.put(`${process.env.REACT_APP_BASE_URL_SERVER}/users/toggle/${paramsNip}`, '', {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const getEmployee = async (paramsNip) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL_SERVER}/users/${paramsNip}`, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const updateEmployee = async (paramsNip, reqData) => {
    const { data } = await axios.put(`${process.env.REACT_APP_BASE_URL_SERVER}/users/${paramsNip}`, reqData, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const resetPasswordEmployee = async (paramsEmail, reqData) => {
    const { data } = await axios.put(`${process.env.REACT_APP_BASE_URL_SERVER}/users/employee/reset/${paramsEmail}`, reqData, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const updatePointEmployee = async (paramsNip, reqData) => {
    const { data } = await axiosJWT.put(`${process.env.REACT_APP_BASE_URL_SERVER}/points/${paramsNip}`, reqData, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}


// Rewards
export const getRewards = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL_SERVER}/rewards`, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const toggleReward = async (paramsId) => {
    const { data } = await axios.put(`${process.env.REACT_APP_BASE_URL_SERVER}/rewards/toggle/${paramsId}`, '', {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const addReward = async (reqData) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL_SERVER}/rewards`, reqData, {
        headers:{
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const getReward = async (paramsId) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL_SERVER}/rewards/${paramsId}`, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const updateReward = async (paramsId, reqData) => {
    const { data } = await axios.put(`${process.env.REACT_APP_BASE_URL_SERVER}/rewards/${paramsId}`, reqData, {
        headers:{
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}


// Log Points
export const getLogPoints = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL_SERVER}/point_logs`, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const getLogRewards = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL_SERVER}/reward_logs?status=Pending`, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const updateLogReward = async (paramsId, reqData) => {
    const { data } = await axios.put(`${process.env.REACT_APP_BASE_URL_SERVER}/reward_logs/${paramsId}`, reqData, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}


// Employee Phones
export const getEmployeePhones = async (paramsNIP) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL_SERVER}/user_phones/${paramsNIP}`, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const addEmployeePhone = async (paramsNIP, reqData) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL_SERVER}/user_phones/${paramsNIP}`, reqData, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const updateEmployeePhone = async (paramsID, reqData) => {
    const { data } = await axios.put(`${process.env.REACT_APP_BASE_URL_SERVER}/user_phones/${paramsID}`, reqData, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const deleteEmployeePhone = async (paramsID) => {
    const { data } = await axios.delete(`${process.env.REACT_APP_BASE_URL_SERVER}/user_phones/${paramsID}`, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}


// Indonesian API
export const getProvinces = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL_SERVER}/provinces`, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const getCities = async (paramsProvince) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL_SERVER}/cities/?province_id=${paramsProvince}`, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const getDistricts = async (paramsCity) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL_SERVER}/districts/?city_id=${paramsCity}`, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const getVillages = async (paramsDistrict) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL_SERVER}/villages/?district_id=${paramsDistrict}`, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}


// Employee Address
export const getEmployeeAddress = async (paramsNIP) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL_SERVER}/user_addresses/${paramsNIP}`, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const addEmployeeAddress = async (paramsNIP, reqData) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL_SERVER}/user_addresses/${paramsNIP}`, reqData, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const updateEmployeeAddress = async (paramsID, reqData) => {
    const { data } = await axios.put(`${process.env.REACT_APP_BASE_URL_SERVER}/user_addresses/${paramsID}`, reqData, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const deleteEmployeeAddress = async (paramsID) => {
    const { data } = await axios.delete(`${process.env.REACT_APP_BASE_URL_SERVER}/user_addresses/${paramsID}`, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}


// Employee Family
export const getEmployeeFamilies = async (paramsNIP) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL_SERVER}/families/${paramsNIP}`, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const addEmployeeFamily = async (paramsNIP, reqData) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL_SERVER}/families/${paramsNIP}`, reqData, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const updateEmployeeFamily = async (paramsID, reqData) => {
    const { data } = await axios.put(`${process.env.REACT_APP_BASE_URL_SERVER}/families/${paramsID}`, reqData, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const deleteEmployeeFamily = async (paramsID) => {
    const { data } = await axios.delete(`${process.env.REACT_APP_BASE_URL_SERVER}/families/${paramsID}`, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}


// Family Phones
export const addFamilyPhone = async (paramsFamilyID, reqData) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL_SERVER}/family_phones/${paramsFamilyID}`, reqData, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const getFamilyPhones = async (paramsNIP) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL_SERVER}/family_phones/employee/${paramsNIP}`, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const updateFamilyPhone = async (paramsID, reqData) => {
    const { data } = await axios.put(`${process.env.REACT_APP_BASE_URL_SERVER}/family_phones/${paramsID}`, reqData, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const deleteFamilyPhone = async (paramsID) => {
    const { data } = await axios.delete(`${process.env.REACT_APP_BASE_URL_SERVER}/family_phones/${paramsID}`, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}


// Family Address
export const addFamilyAddress = async (paramsFamilyID, reqData) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL_SERVER}/family_addresses/${paramsFamilyID}`, reqData, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const getFamilyAddress = async (paramsNIP) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL_SERVER}/family_addresses/employee/${paramsNIP}`, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const updateFamilyAddress = async (paramsID, reqData) => {
    const { data } = await axios.put(`${process.env.REACT_APP_BASE_URL_SERVER}/family_addresses/${paramsID}`, reqData, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const deleteFamilyAddress = async (paramsID) => {
    const { data } = await axios.delete(`${process.env.REACT_APP_BASE_URL_SERVER}/family_addresses/${paramsID}`, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}


// Office Phones
export const getOfficePhones = async (paramsSlug) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL_SERVER}/office_phones/${paramsSlug}`, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const addOfficePhone = async (paramsSlug, reqData) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL_SERVER}/office_phones/${paramsSlug}`, reqData, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const updateOfficePhone = async (paramsID, reqData) => {
    const { data } = await axios.put(`${process.env.REACT_APP_BASE_URL_SERVER}/office_phones/${paramsID}`, reqData, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const deleteOfficePhone = async (paramsID) => {
    const { data } = await axios.delete(`${process.env.REACT_APP_BASE_URL_SERVER}/office_phones/${paramsID}`, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}


// Office Address
export const getOfficeAddress = async (paramsSlug) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL_SERVER}/office_addresses/${paramsSlug}`, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const addOfficeAddress = async (paramsSlug, reqData) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL_SERVER}/office_addresses/${paramsSlug}`, reqData, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const updateOfficeAddress = async (paramsID, reqData) => {
    const { data } = await axios.put(`${process.env.REACT_APP_BASE_URL_SERVER}/office_addresses/${paramsID}`, reqData, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const deleteOfficeAddress = async (paramsID) => {
    const { data } = await axios.delete(`${process.env.REACT_APP_BASE_URL_SERVER}/office_addresses/${paramsID}`, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}


// change password profile
export const changePassword = async (paramsNIP, reqData) => {
    const { data } = await axiosJWT.put(`${process.env.REACT_APP_BASE_URL_SERVER}/users/employee/password/${paramsNIP}`, reqData, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}



// add roles employee
export const getRolesEmployee = async (paramsNIP) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL_SERVER}/positions/assigned/${paramsNIP}`, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const addRolesEmployee = async (paramsNIP, reqData) => {
    const { data } = await axios.put(`${process.env.REACT_APP_BASE_URL_SERVER}/positions/assign/${paramsNIP}`, reqData, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}


export const deletRolesEmployee = async (paramsID) => {
    const { data } = await axios.delete(`${process.env.REACT_APP_BASE_URL_SERVER}/office_addresses/${paramsID}`, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}


// embedded map open street
export const getStreet = async (paramsQuery) => {
    const { data } = await axios.get(`${process.env.REACT_APP_OPEN_STREET_MAP}/search?format=json&q=${paramsQuery}`)
    return data;
}


// get absesnse by filter
export const getPendingAbsents = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL_SERVER}/absences?status=pending`, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const getFilteredAbsents = async (paramsDivision, paramsStartDate, paramsEndDate) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL_SERVER}/absences?division_slug=${paramsDivision}&start_date=${paramsStartDate}&end_date=${paramsEndDate}`, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const updateAbsent = async (paramsId, reqData) => {
    const { data } = await axios.put(`${process.env.REACT_APP_BASE_URL_SERVER}/absences/${paramsId}`, reqData, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const createAbsent = async (reqData) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL_SERVER}/absences/success`, reqData, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

// overtimes
export const getPendingOvertimes = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL_SERVER}/overtimes?status=pending`, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const getFilteredOvertimes = async (paramsDivision, paramsStartDate, paramsEndDate) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL_SERVER}/overtimes?division_slug=${paramsDivision}&start_date=${paramsStartDate}&end_date=${paramsEndDate}`, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const updateOvertime = async (paramsId, reqData) => {
    const { data } = await axios.put(`${process.env.REACT_APP_BASE_URL_SERVER}/overtimes/${paramsId}`, reqData, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const createOvertime = async (reqData) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL_SERVER}/overtimes/success`, reqData, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}


// attendances
export const getFilteredAttendances = async (paramsDivision, paramsStatus, paramsNIP, paramsStartDate, paramsEndDate) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL_SERVER}/attendances?start_date=${paramsStartDate}&end_date=${paramsEndDate}&division_slug=${paramsDivision}&status=${paramsStatus}&nip=${paramsNIP}`, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const updateAttendance = async (paramsId, reqData) => {
    const { data } = await axios.put(`${process.env.REACT_APP_BASE_URL_SERVER}/attendances/update/${paramsId}`, reqData, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const createAttendance = async (reqData) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL_SERVER}/attendances/user`, reqData, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}


// attendance reports
export const getFilteredAttendanceReorts = async (paramsDivision, paramsStatus, paramsNIP, paramsStartDate, paramsEndDate) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL_SERVER}/attendances/report?start_date=${paramsStartDate}&end_date=${paramsEndDate}&division_slug=${paramsDivision}&status=${paramsStatus}&nip=${paramsNIP}`, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}


export const getMasterData = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL_SERVER}/master_data`, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const updateMasterData = async (paramsId, reqData) => {
    const { data } = await axios.put(`${process.env.REACT_APP_BASE_URL_SERVER}/master_data/${paramsId}`, reqData, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

export const getContractEnd = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL_SERVER}/users/employees/contract_end`, {
        headers:{
            Authorization: `Bearer ${tokenStorage}`
        }
    })
    return data;
}

// profile user
// export const getProfile = async (userLogin, token) => {
//     const {data} = await axios.get(`${process.env.REACT_APP_BASE_URL}/authentication/userLogin.php?me=${userLogin}`, {
//         headers:{
//             Authorization: `Bearer ${token}`
//         }
//     })
//     return data;
// }

// export const updateProfile = async (paramsUUID, reqData, token) => {
//     const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/authentication/userUpdate.php?me=${paramsUUID}`, reqData, {
//         headers:{
//             Authorization: `Bearer ${token}`
//         }
//     })
//     return data;
// }

// export const getMe = async (userLogin, dispatch) => {
//     try {
//         const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/authentication/userLogin.php?id=${userLogin}`);
//         dispatch({ type: "GET_ME", payload: response.data.payload });
//     } catch (error) {
//         dispatch({ type: "GET_ME_FAILURE", payload: error.response.data})
//     }
// }

// export const searchEnterprisers = async (keyword, token) => {
//     const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/enterprisers/searchEnterprisers.php?key=${keyword}`, {
//         headers:{
//             Authorization: `Bearer ${token}`
//         }
//     })
//     return data;
// }