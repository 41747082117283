export const sidebarMenus = [
    {
        title: 'Dashboard', 
        icon: '/assets/icons/Dashboard.svg',
        link: '/',
        roles: ["admin", "superadmin", "event"],
        subMenus: []
    },
    {
        title: 'Offices', 
        icon: '/assets/icons/Shop.svg',
        link: '/offices',
        roles: ["superadmin"],
        subMenus: []
    },
    {
        title: 'News Update', 
        icon: '/assets/icons/News.svg',
        link: '/posts',
        roles: ["superadmin", "admin"],
        subMenus: []
    },
    // {
    //     title: 'Employees', 
    //     icon: '/assets/icons/Employees.svg',
    //     link: '/employees',
    //     roles: ["superadmin", "admin"],
    //     subMenus: []
    // },
    {
        title: 'Employees', 
        icon: '/assets/icons/Employees.svg',
        link: '',
        roles: ["superadmin", "admin"],
        subMenus: [
            {
                subTitle: 'Employees',
                link: '/employees',
            },
            {
                subTitle: 'Employee Status',
                link: '/employee-status',
            },
            {
                subTitle: 'Eselon',
                link: '/eselons',
            },
            {
                subTitle: 'Position',
                link: '/positions',
            },
        ]
    },
    {
        title: 'Points', 
        icon: '/assets/icons/Audios.svg',
        link: '',
        roles: ["superadmin"],
        subMenus: [
            {
                subTitle: 'Rewards',
                link: '/rewards',
            },
            {
                subTitle: 'Log Rewards',
                link: '/log-rewards',
            },
            {
                subTitle: 'Log Points',
                link: '/points',
            },
        ]
    },
    // {
    //     title: 'Eslon', 
    //     icon: '/assets/icons/Gallery.svg',
    //     link: '/echelons',
    //     roles: ["superadmin"],
    //     subMenus: []
    // },
    // {
    //     title: 'Position', 
    //     icon: '/assets/icons/Gallery.svg',
    //     link: '/positions',
    //     roles: ["superadmin"],
    //     subMenus: []
    // },
    {
        title: 'Attendances', 
        icon: '/assets/icons/Report-Attendance.svg',
        link: '',
        roles: ["superadmin"],
        subMenus: [
            {
                subTitle: 'Attendances',
                link: '/attendances',
            },
            {
                subTitle: 'Lembur/WFA',
                link: '/ovt',
            },
            {
                subTitle: 'Absents',
                link: '/absents',
            },
            {
                subTitle: 'Report',
                link: '/attendances-report',
            },
        ]
    },
    // {
    //     title: 'Attendances', 
    //     icon: '/assets/icons/Attendance.svg',
    //     link: '/attendances',
    //     roles: ["superadmin", "admin"],
    //     subMenus: []
    // },
    // {
    //     title: 'Lembur/WFA', 
    //     icon: '/assets/icons/Lembur.svg',
    //     link: '/ovt',
    //     roles: ["superadmin", "admin"],
    //     subMenus: []
    // },
    // {
    //     title: 'Absents', 
    //     icon: '/assets/icons/Absent.svg',
    //     link: '/absents',
    //     roles: ["superadmin", "admin"],
    //     subMenus: []
    // },
    // {
    //     title: 'Attendance Report', 
    //     icon: '/assets/icons/Report-Attendance.svg',
    //     link: '/report-attendances',
    //     roles: ["superadmin", "admin"],
    //     subMenus: []
    // },
    {
        title: 'Informations', 
        icon: '/assets/icons/Information.svg',
        link: '/informations',
        roles: ["superadmin"],
        subMenus: []
    },
    {
        title: 'Utilities', 
        icon: '/assets/icons/Gallery.svg',
        link: '/utilities',
        roles: ["superadmin"],
        subMenus: []
    },
]