import React, { useContext, useEffect, useState } from 'react';
import { FaUsers } from "react-icons/fa";
import { ImOffice } from "react-icons/im";
import { FaGifts } from "react-icons/fa";
import { MdTimer } from "react-icons/md";
import { FaPaste } from "react-icons/fa";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { toast } from 'react-toastify';
import { getContractEnd, getEmployees, getLogRewards, getOffices, getPendingAbsents, getPendingOvertimes, logoutUser } from '../../utilities/sendRequest';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/authContext';
import { dateIndo } from '../../utilities/helper';


const Dashboard = () => {
    const [offices, setOffices] = useState(0)
    const [employees, setEmployees] = useState(0)
    const [overtimes, setOvertimes] = useState(0)
    const [absents, setAbsents] = useState(0)
    const [rewards, setRewards] = useState(0)
    const { dispatch } = useContext(AuthContext)
    const [datas, setDatas] = useState([])

    useEffect(() => {
        fetchOffices();
        fetchEmployees();
        fetchOvertimes();
        fetchAbsents();
        fetchRewards();
        fecthContracts();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchOffices = async () => {
        getOffices().then((res) => {
            setOffices(res.payload.filter(office => office.is_active === true).length)
        }).catch((err) => {
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });

            if (err.response.status === 401) {
                setTimeout(() => {
                    logoutUser(dispatch)
                }, 3500)
            }
        })  
    }

    const fetchEmployees = async () => {
        getEmployees().then((res) => {
            setEmployees(res.payload.filter(employee => employee.is_active === true).length)
        }).catch((err) => {
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })  
    }

    const fetchOvertimes = async () => {
        getPendingOvertimes().then((res) => {
            setOvertimes(res.payload.filter(overtime => overtime.status === "pending").length)
        }).catch((err) => {
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })  
    }

    const fetchAbsents = async () => {
        getPendingAbsents().then((res) => {
            setAbsents(res.payload.filter(absent => absent.status === "pending").length)
        }).catch((err) => {
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })  
    }

    const fetchRewards = async () => {
        getLogRewards().then((res) => {
            setRewards(res.payload.filter(reward => reward.status === "pending").length)
        }).catch((err) => {
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })  
    }

    const fecthContracts = async () => {
        getContractEnd().then((res) => {
            setDatas(res.payload)
        }).catch((error) => {
            toast.error(error.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });

            if (error.response.status === 401) {
                setTimeout(() => {
                    logoutUser(dispatch)
                }, 3500)
            }
        })
    }

    const columns = [
        {
            field: 'nip',
            headerName: 'NIP',
            width: 150,
            renderCell: (params) => {
                return (
                    <div className="font-ssp capitalize">
                        {params.row.nip}
                    </div>
                )
            } 
        },
        { 
            field: 'fullname', 
            headerName: 'Fullname',
            width: 350, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {params.row.fullname}
                    </div>
                )
            } 
        },
        {
            field: 'Employment_Periode',
            headerName: 'Expired Date',
            width: 200,
            renderCell: (params) => {
                return (
                    <div className="font-ssp capitalize">
                        {dateIndo(params.row.Employment_Periode)}
                    </div>
                )
            } 
        },
        {
            field: 'Employment_Status',
            headerName: 'Status',
            width: 250,
            renderCell: (params) => {
                return (
                    <div className="font-ssp capitalize">
                        {params.row.Employment_Status}
                    </div>
                )
            } 
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 150,
            renderCell: (params) => {
                return (
                    <div className='w-full flex flex-row gap-3 items-center'>
                        <Link to={"/employees/" + params.row.nip}>
                            <VisibilityIcon fontSize='medium' className="text-slate-400 hover:text-slate-500 cursor-pointer"/>
                        </Link>
                    </div>
                )
            }
        },
    ]

    return (
        <div className='min-h-[calc(100vh-4rem)] flex flex-col py-5 px-7 w-full items-center'>
            <div className="my-5 mb-5 mx-7 md:mx-5 grid md:grid-cols-2 lg:grid-cols-4 gap-7 w-full">
                <Link to={"/offices"} className='px-5 py-7 w-full md:w-[270px] flex flex-row justify-start gap-5 items-center rounded-xl bg-white cursor-pointer'>
                    <div className='flex-none w-20 flex justify-center items-center'>
                        <ImOffice className='w-16 h-16'/>
                    </div>
                    <div className='flex-1 flex flex-col'>
                        <h5 className='font-ssp text-6xl font-bold'>{offices}</h5>
                        <h3 className='font-ssp text-xl font-semibold'>Offices</h3>
                    </div>
                </Link>
                <Link to={"/employees"} className='px-5 py-7 w-full md:w-[270px] flex flex-row justify-start gap-5 items-center rounded-xl bg-white cursor-pointer'>
                    <div className='flex-none w-20 flex justify-center items-center'>
                        <FaUsers className='w-16 h-16'/>
                    </div>
                    <div className='flex-1 flex flex-col'>
                        <h5 className='font-ssp text-6xl font-bold'>{employees}</h5>
                        <h3 className='font-ssp text-xl font-semibold'>Employees</h3>
                    </div>
                </Link>
                <Link to={"/ovt"} className='px-5 py-7 w-full md:w-[270px] flex flex-row justify-start gap-5 items-center rounded-xl bg-white cursor-pointer'>
                    <div className='flex-none w-20 flex justify-center items-center'>
                        <MdTimer className='w-16 h-16'/>
                    </div>
                    <div className='flex-1 flex flex-col'>
                        <h5 className='font-ssp text-6xl font-bold'>{overtimes}</h5>
                        <h3 className='font-ssp text-xl font-semibold'>Lembur/WFA</h3>
                    </div>
                </Link>
                <Link to={"/absents"} className='px-5 py-7 w-full md:w-[270px] flex flex-row justify-start gap-5 items-center rounded-xl bg-white cursor-pointer'>
                    <div className='flex-none w-20 flex justify-center items-center'>
                        <FaPaste className='w-16 h-16'/>
                    </div>
                    <div className='flex flex-col flex-1 w-auto'>
                        <h5 className='font-ssp text-6xl font-bold'>{absents}</h5>
                        <h3 className='font-ssp text-xl font-semibold'>Ijin/Cuti/Sakit</h3>
                    </div>
                </Link>
                <Link to={"/log-rewards"} className='px-5 py-7 w-full md:w-[270px] flex flex-row justify-start gap-5 items-center rounded-xl bg-white cursor-pointer'>
                    <div className='flex-none w-20 flex justify-center items-center'>
                        <FaGifts className='w-16 h-16'/>
                    </div>
                    <div className='flex flex-col flex-1 w-auto'>
                        <h5 className='font-ssp text-6xl font-bold'>{rewards}</h5>
                        <h3 className='font-ssp text-xl font-semibold'>Redeem</h3>
                    </div>
                </Link>
            </div>

            {/* masa kerja */}
            <div className="my-5 mb-5 mx-7 md:mx-5 w-full">
                <div className="my-5 mx-7 md:mx-5 flex flex-col md:flex-row justify-between">
                    <h1 className='font-ssp font-semibold text-xl md:text-2xl'>Contract Reminder</h1>
                </div>
                {/* content  */}
                <div className="my-5 mb-5 mx-7 md:mx-5 flex">
                    <div className="p-2 w-full flex bg-white drop-shadow-lg rounded-md">
                        <DataGrid
                            autoHeight {...datas}
                            rows={datas}
                            columns={columns}
                            pageSize={10}
                            getRowId={(row) => row.nip}
                            rowsPerPageOptions={[10]}
                            checkboxSelection
                            disableSelectionOnClick
                            components={{ Toolbar: GridToolbar, }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard