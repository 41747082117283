import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const ExportToExcel = ({ reportDatas, fileName }) => {
	const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
	const fileExtension = ".xlsx";

	const exportToCSV = (reportDatas, fileName) => {
		const ws = XLSX.utils.json_to_sheet(reportDatas);
		const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
		const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
		const data = new Blob([excelBuffer], { type: fileType });
		FileSaver.saveAs(data, fileName + fileExtension);
	};

	return (
        <div onClick={(e) => exportToCSV(reportDatas, fileName)} className="px-5 py-2.5 mr-3 mt-3 md:mt-1 bg-yellow-400 hover:bg-yellow-500 rounded-lg flex text-sm font-medium self-start md:self-end cursor-pointer">
            <span className='text-sm text-white font-ssp'>Download</span>
        </div>
	)
}


export default ExportToExcel
