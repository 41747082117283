import React, { useEffect, useState, useContext } from 'react';
import { toast } from 'react-toastify';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { AuthContext } from '../../context/authContext';
import { PopupContext } from "../../context/popupContext";
import { logoutUser, getEmployeeStatus, addEmployeeStatus, toggleEmployeeStatus } from '../../utilities/sendRequest';
import { statusOptions } from '../../utilities/selectOptions';
import ModalEmployeeStatus from '../../components/EmployeeStatus/modalEmployeeStatus';

const EmployementStatus = () => {
    const { dispatch } = useContext(AuthContext)
    const { popupDispatch } = useContext(PopupContext)
    const [datas, setDatas] = useState([])
    const [showModalDetail, setShowModalDetail] = useState(false)
    const [fetchMore, setFetchMore] = useState(true)
    const [isReady, setIsReady] = useState(false)
    const [title, setTitle] = useState("")
    const [status, setStatus] = useState("")
    const [data, setData] = useState([])

    useEffect(() => {
        const isReady = (title, status);
        setIsReady(isReady);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [title, status])

    useEffect(() => {
        fetchEmployeeStatus()
        setFetchMore(false)

        // eslint-disable-next-line
    }, [fetchMore])

    const fetchEmployeeStatus = async () => {
        getEmployeeStatus().then((res) => {
            setDatas(
                res.payload.sort((p1, p2) => {
                    return new Date(p2.createdAt) - new Date(p1.createdAt)
                })
            )
        }).catch((err) => {
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });

            if (err.response.status === 401) {
                setTimeout(() => {
                    logoutUser(dispatch)
                }, 2500)
            }
        })
    }

    const handleCreate = async (e) => {
        e.preventDefault()

        setIsReady(false)

        const reqData = {
            name: title,
            is_active: status,
        }

        addEmployeeStatus(reqData).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
            setFetchMore(true)
            resetForm()
        }).catch((err) => {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        }).finally(() => {
            setIsReady(true)
        })
    }

    const resetForm = () => {
        setTitle("")
        setStatus("")
    }

    const handleModal = (row) => {
        setShowModalDetail(true)
        setData(row)
        popupDispatch({ type: "POPUP_START" });
    }

    const handleToggle = (paramsId) => {
        toggleEmployeeStatus(paramsId).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
            setFetchMore(true)
        }).catch((error) => {
            toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });

            if (error.response.status === 401) {
                setTimeout(() => {
                    logoutUser(dispatch)
                }, 3500)
            }
        })
    }

    const columns = [
        { 
            field: 'name', 
            headerName: 'Name',
            width: 200, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {params.row.name}
                    </div>
                )
            } 
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 150,
            renderCell: (params) => {
                return (
                    <div className="font-ssp">
                        {params.row.is_active
                            ? <span className="px-2 py-1 bg-green-400 rounded-full text-white uppercase">active</span>
                            : <span className="px-2 py-1 bg-red-400 rounded-full text-white uppercase">inactive</span>
                        }
                    </div>
                )
            } 
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 150,
            renderCell: (params) => {
                return (
                    <div className='w-full flex flex-row items-center gap-3'>
                        <VisibilityIcon fontSize='medium' className="text-slate-400 hover:text-slate-500 cursor-pointer" onClick={() => handleModal(params.row)}/>
                        {params.row.is_active
                            ? <ToggleOnIcon className="text-green-400 hover:text-green-500 cursor-pointer" onClick={() => handleToggle(params.row.id)}/>
                            : <ToggleOffIcon className="text-gray-400 hover:text-gray-500 cursor-pointer" onClick={() => handleToggle(params.row.id)}/>
                        }
                    </div>
                )
            }
        },
    ]

    return (
        <>
            {showModalDetail && <ModalEmployeeStatus data={data} setShowModalDetail={setShowModalDetail} setFetchMore={setFetchMore} />}
            <div className={`min-h-[calc(100vh-4rem)] flex flex-col ${showModalDetail && 'fixed min-w-[calc(100vw-16.666667%)]'} `}>
                {/* header  */}
                <div className="my-5 mb-5 mx-7 md:mx-5 flex flex-col md:flex-row justify-between">
                    <h1 className='font-ssp font-bold text-2xl md:text-3xl'>Employement Status</h1>
                </div>
                {/* content  */}
                <div className="my-5 mx-7 md:mx-5 flex flex-col lg:flex-row justify-evenly gap-7 md:gap-10">
                    {/* list data */}
                    <div className='w-full h-full lg:w-[50%] flex flex-col gap-3 items-center'>
                        <div className="p-2 w-full bg-white drop-shadow-lg rounded-md flex">
                            <DataGrid
                                autoHeight {...datas}
                                rows={datas}
                                columns={columns}
                                pageSize={10}
                                getRowId={(row) => row.id}
                                rowsPerPageOptions={[10]}
                                checkboxSelection
                                disableSelectionOnClick
                                components={{ Toolbar: GridToolbar, }}
                            />
                        </div>
                    </div>

                    {/* form store */}
                    <div className="px-2 py-4 w-full h-fit lg:w-[50%] bg-white drop-shadow-lg rounded-md flex flex-col gap-3">
                        <h1 className='font-semibold text-base font-ssp px-3'>Create New Status</h1>
                        <hr className='px-3'/>
                        <div className=" py-1 w-full h-fit flex">
                            <form onSubmit={handleCreate} className='px-5 w-full flex flex-col justify-between'>
                                {/* name */}
                                <div className="mt-3 mb-6 w-full relative z-0 group">
                                    <input 
                                        value={title} 
                                        onChange={(e) => setTitle(e.target.value)} 
                                        type="text" 
                                        name="title" 
                                        id="title" 
                                        className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                                    border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                                    focus:border-blue-600 peer" 
                                        placeholder=" " 
                                        required 
                                    />
                                    <label 
                                        htmlFor="title" 
                                        className="absolute top-3 -z-10 text-sm text-gray-500 
                                                    duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                                    peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                                    peer-focus:scale-75 peer-focus:-translate-y-6"
                                    >Name</label>
                                </div>
                                {/* status */}
                                <div className="mt-3 mb-6 w-full relative z-0 group">
                                    <select 
                                        required 
                                        value={status} 
                                        onChange={(e) => setStatus(e.target.value)} 
                                        id="status" 
                                        className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent 
                                                    border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                                    focus:border-blue-600 peer"
                                    >
                                        {statusOptions.map((option) => (
                                            <option key={option.value} value={option.value}>{option.label}</option>
                                        ))}
                                    </select>
                                    <label 
                                        htmlFor="status" 
                                        className="absolute top-3 -z-10 text-sm text-gray-500 
                                                    duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                                    peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                                    peer-focus:scale-75 peer-focus:-translate-y-6"
                                    >Status</label>
                                </div>
                                {/* button */}
                                <div className="mb-6 w-full relative z-0 flex justify-center gap-5">
                                    <button 
                                        type="submit" disabled={!isReady}
                                        className={`mt-5 px-5 py-2.5 w-fit rounded-lg sm:w-autorounded-lg font-medium text-sm text-center ${isReady ? 'text-white bg-green-400 hover:bg-green-500 cursor-pointer' : 'text-gray-800 bg-gray-300 cursor-wait'}`}
                                    >Create</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmployementStatus