import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getMasterData, updateMasterData } from '../../utilities/sendRequest';

const MasterWeb = () => {
    const [isReady, setIsReady] = useState(false)
    const [fetchAgain, setFetchAgain] = useState(false)
    const [cuti, setCuti] = useState("")
    
    useEffect(() => {
        const isReady = (cuti !== "");
        setIsReady(isReady);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cuti])
 
    useEffect(() => {
        fetchMasterWeb()
        setFetchAgain(false)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchAgain])

    const fetchMasterWeb = async () => {
        getMasterData().then((res) => {
            setCuti(res.payload[0].annual_leave)
        }).catch((err) => {
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })  
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        setIsReady(false)
        const reqData = {
            annual_leave: cuti
        }

        updateMasterData(1, reqData).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
            setFetchAgain(true)
        }).catch((error) => {
            toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        }).finally(() => {
            setIsReady(true)
        })
    }

    return (
        <div className='min-h-[calc(100vh-4rem)] flex flex-col'>
            {/* header */}
            <div className="my-5 mx-7 md:mx-5 flex flex-row justify-between">
                <h1 className='font-ssp font-bold text-4xl md:text-3xl'>Update Master Web</h1>
            </div>
            {/* content */}
            <div className="my-5 mx-7 md:mx-5 flex flex-col lg:flex-row gap-10">
                {/* form update */}
                <div className="px-2 py-4 w-full flex bg-white drop-shadow-lg rounded-md">
                    <form onSubmit={handleSubmit} className='px-5 py-3 w-full flex flex-wrap justify-between'>
                        {/* cuti */}
                        <div className="relative z-0 mb-6 w-full md:w-[30%] group">
                            <input 
                                value={cuti} 
                                onChange={(e) => setCuti(e.target.value)} 
                                type="text" 
                                name="cuti" 
                                id="cuti" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 
                                            appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                                placeholder=" " 
                            />
                            <label 
                                htmlFor="cuti" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Jumlah Cuti</label>
                        </div>
                        {/* button */}
                        <div className="mb-6 w-full relative z-0 flex justify-center gap-5">
                            <button 
                                type="submit" 
                                disabled={!isReady}
                                className={`mt-5 px-5 py-2.5 w-fit rounded-lg sm:w-autorounded-lg font-medium text-sm text-center ${isReady ? 'text-white bg-green-400 hover:bg-green-500 cursor-pointer' : 'text-gray-800 bg-gray-300 cursor-wait'}`}
                            >Update</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default MasterWeb