export const mainOptions = [
    {
        label: "Choose a Status",
        value: "",
    },
    {
        label: "OPSIONAL",
        value: false,
    },
    {
        label: "UTAMA",
        value: true,
    },
]

export const absentOptions = [
    {
        label: "Choose a Status",
        value: "",
    },
    {
        label: "PENDING",
        value: "pending",
    },
    {
        label: "FAILED",
        value: "failed",
    },
    {
        label: "SUCCESS",
        value: "success",
    },
]

export const absentTypeOptions = [
    {
        label: "Choose a Type",
        value: "",
    },
    {
        label: "SAKIT",
        value: "Sakit",
    },
    {
        label: "IJIN",
        value: "Ijin",
    },
    {
        label: "CUTI",
        value: "Cuti",
    },
]

export const overtimeTypeOptions = [
    {
        label: "Choose a Type",
        value: "",
    },
    {
        label: "LEMBUR",
        value: "OVT",
    },
    {
        label: "WFA",
        value: "WFA",
    },
]

export const attendanceTypeOptions = [
    {
        label: "WFO",
        value: "WFO",
    },
    {
        label: "WFA",
        value: "WFA",
    },
]

export const codeOptions = [
    {
        label: "Choose a Code",
        value: "",
    },
    {
        label: "Indonesia (+62)",
        value: 62,
    },
    {
        label: "Australia (+61)",
        value: 61,
    },
    {
        label: "Malaysia (+60)",
        value: 60,
    },
    {
        label: "Singapura (+65)",
        value: 65,
    },
    {
        label: "Hongkong (+852)",
        value: 852,
    },
]

export const typeOptions = [
    {
        label: "Choose a Type",
        value: ""
    }, 
    {
        label: "PENAMBAHAN",
        value: "revenue"
    },
    {
        label: "PENGURANGAN",
        value: "expense"
    },
]

export const statusOptions = [
    {
        label: "Choose a Status",
        value: "",
    },
    {
        label: "ACTIVE",
        value: true,
    },
    {
        label: "INACTIVE",
        value: false,
    },
]

export const employeeTypeOptions = [
    {
        label: "PERMANENT",
        value: true,
    },
    {
        label: "CONTRACT",
        value: false,
    },
]

export const familyOptions = [
    {
        label: "Choose a Status",
        value: "",
    },
    {
        label: "AYAH",
        value: "AYAH",
    },
    {
        label: "IBU",
        value: "IBU",
    },
    {
        label: "SUAMI",
        value: "SUAMI",
    },
    {
        label: "ISTRI",
        value: "ISTRI",
    },
    {
        label: "ANAK",
        value: "ANAK",
    },
    {
        label: "SAUDARA",
        value: "SAUDARA",
    },
]

export const postTypeOptions = [
    {
        label: "Choose a Type",
        value: "",
    },
    {
        label: "Event",
        value: "event",
    },
    {
        label: "Article",
        value: "article",
    },
]

export const employeeStatusOptions = [
    {
        label: "Choose a Status",
        value: "",
    },
    {
        label: "ABSENT",
        value: "absent",
    },
    {
        label: "OVERTIME",
        value: "overtime",
    },
    {
        label: "ANNUAL",
        value: "annual",
    },
    {
        label: "ATTENDANCE",
        value: "attendance",
    },
    {
        label: "COMPANY",
        value: "company",
    },
]