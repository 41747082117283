import React, { useEffect, useState } from 'react';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { format } from 'date-fns';
import { dateIndo, formatTanggal, timeIndo } from '../../utilities/helper';
import ExportToExcel from '../../components/Exports/ExportToExcel';
import ModalFilterAttendanceReport from '../../components/AttendanceReport/modalFilterAttendanceReport';

const AttendanceReport = () => {
    const [datas, setDatas] = useState([])
    const [showModalFilter, setShowModalFilter] = useState(false)
    const [fetchMore, setFetchMore] = useState(true)
    const [startTime, setStartTime] = useState("")
    const [endTime, setEndTime] = useState("")
    const [employee, setEmployee] = useState("")
    const [statusTitle, setStatusTitle] = useState("")
    const [division, setDivision] = useState("")
	const fileName = "Report_Attendances_" + format(new Date(), "yyyy-MM-dd_HH:mm:ss");

    useEffect(() => {
        setFetchMore(false)
        
        // eslint-disable-next-line
    }, [fetchMore])

    const columns = [
        { 
            field: 'date', 
            headerName: 'Tanggal',
            width: 150, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {/* {dateIndo(params.row.date)} */}
                        {formatTanggal(params.row.date)}
                    </div>
                )
            } 
        },
        { 
            field: 'fullname', 
            headerName: 'Pegawai',
            width: 250, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {params.row.nip} - {params.row.fullname}
                    </div>
                )
            } 
        },
        { 
            field: 'office', 
            headerName: 'Divisi',
            width: 200, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {params.row.office}
                    </div>
                )
            } 
        },
        { 
            field: 'attendance_clock_in', 
            headerName: 'Jam Masuk',
            width: 150, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {params.row.attendance_clock_in ? params.row.attendance_clock_in : "-"}
                    </div>
                )
            } 
        },
        { 
            field: 'attendance_location_in', 
            headerName: 'Lokasi Masuk',
            width: 250, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {params.row.attendance_location_in ? params.row.attendance_location_in : "-"}
                    </div>
                )
            } 
        },
        { 
            field: 'attendance_clock_out', 
            headerName: 'Jam Keluar',
            width: 150, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {params.row.attendance_clock_out ? params.row.attendance_clock_out : "-"}
                    </div>
                )
            } 
        },
        { 
            field: 'attendance_location_out', 
            headerName: 'Lokasi Pulang',
            width: 250, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {params.row.attendance_location_out ? params.row.attendance_location_out : "-"}
                    </div>
                )
            } 
        },
        { 
            field: 'attendance_status', 
            headerName: 'Status',
            width: 150, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp uppercase'>
                        {params.row.attendance_status ? params.row.attendance_status : "-"}
                    </div>
                )
            } 
        },
        { 
            field: 'attendance_note', 
            headerName: 'Note',
            width: 150, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp uppercase'>
                        {params.row.attendance_note ? params.row.attendance_note : "-"}
                    </div>
                )
            } 
        },

        { 
            field: 'overtime_start_time', 
            headerName: 'Mulai Lembur',
            width: 150, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp uppercase'>
                        {params.row.overtime_start_time ? `${dateIndo(params.row.overtime_start_time)} ${timeIndo(params.row.overtime_start_time)}` : "-"}
                    </div>
                )
            } 
        },
        { 
            field: 'overtime_end_time', 
            headerName: 'Selesai Lembur',
            width: 150, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp uppercase'>
                        {params.row.overtime_end_time ? `${dateIndo(params.row.overtime_end_time)} ${timeIndo(params.row.overtime_end_time)}` : "-"}
                    </div>
                )
            } 
        },
        { 
            field: 'overtime_note', 
            headerName: 'Note Lembur',
            width: 150, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp uppercase'>
                        {params.row.overtime_note ? params.row.overtime_note : "-"}
                    </div>
                )
            } 
        },


        { 
            field: 'absence_start_date', 
            headerName: 'Tanggal Mulai Absen',
            width: 150, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp uppercase'>
                        {params.row.absence_start_date ? dateIndo(params.row.absence_start_date) : "-"}
                    </div>
                )
            } 
        },
        { 
            field: 'absence_end_date', 
            headerName: 'Tanggal Selesai Absen',
            width: 170, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp uppercase'>
                        {params.row.absence_end_date ? dateIndo(params.row.absence_end_date) : "-"}
                    </div>
                )
            } 
        },
        { 
            field: 'absence_type', 
            headerName: 'Tipe Absen',
            width: 150, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp uppercase'>
                        {params.row.absence_type ? params.row.absence_type : "-"}
                    </div>
                )
            } 
        },
        { 
            field: 'absence_note', 
            headerName: 'Note Absen',
            width: 150, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp uppercase'>
                        {params.row.absence_note ? params.row.absence_note : "-"}
                    </div>
                )
            } 
        },
    ]

    // const reportDatas = datas
    const reportDatas = datas.map(item => (
		{ 
			"Tanggal": formatTanggal(item.date), 
			"Nama Lengkap": item.fullname, 
			"NIP": item.nip, 
			"Divisi": item.office, 
			"Jam Masuk": item.attendance_clock_in,
            "Lokasi Masuk": item.attendance_location_in, 
			"Jam Pulang": item.attendance_clock_out, 
            "Lokasi Pulang": item.attendance_location_out,
			"Note Kehadiran": item.attendance_note, 
			"Status": item.attendance_status,
			"Mulai Lembur": item.overtime_start_time ? `${dateIndo(item.overtime_start_time)} ${timeIndo(item.overtime_start_time)}` : "",
			"Selesai Lembur": item.overtime_end_time ? `${dateIndo(item.overtime_end_time)} ${timeIndo(item.overtime_end_time)}` : "",
			"Note Lembur": item.overtime_note,
			"Tanggal Mulai Absen": item.absence_start_date ? dateIndo(item.absence_start_date) : "",
			"Tanggal Selesai Absen": item.absence_end_date ? dateIndo(item.absence_end_date) : "",
			"Tipe Absen": item.absence_type,
			"Note Absen": item.absence_note,
			"Note HRD": '',
		}
	));

    return (
        <>
            {showModalFilter && 
                <ModalFilterAttendanceReport setShowModalFilter={setShowModalFilter} setDatas={setDatas} setFetchMore={setFetchMore} setStartTime={setStartTime} setEndTime={setEndTime} setDivision={setDivision} setEmployee={setEmployee} setStatusTitle={setStatusTitle} />
            }

            <div className={`min-h-[calc(100vh-4rem)] flex flex-col ${showModalFilter && 'fixed min-w-[calc(100vw-16.666667%)]'}`}>
                {/* header  */}
                <div className="my-5 mb-5 mx-7 md:mx-5 flex flex-col md:flex-row justify-between items-start">
                    <div className=' flex flex-col'>
                        <h1 className='font-ssp font-bold text-2xl md:text-3xl'>Attendance Report</h1>
                        {startTime 
                            ?
                                <span className='font-ssp font-normal text-sm md:text-xl'>{dateIndo(startTime)} - {dateIndo(endTime)}</span>
                            : ''
                        }
                        {division && statusTitle
                            ? <span className='font-ssp font-normal text-sm md:text-xl'>{division} | {employee ? employee : "All Employee"} | {statusTitle}</span> 
                            : <span className='font-ssp font-light text-sm md:text-base tracking-wide'>Lakukan filter tanggal dulu untuk menampilkan data</span>
                        }
                    </div>
                    <div className='flex flex-row flex-wrap gap-2'>
                        <div onClick={(e) => setShowModalFilter(true)} className="px-5 py-2.5 mr-3 mt-3 md:mt-1 bg-green-400 hover:bg-green-500 rounded-lg flex text-sm font-medium self-start md:self-end cursor-pointer">
                            <span className='text-sm text-white font-ssp'>Filter By Date</span>
                        </div>
                        {division && statusTitle
                            ?
                                <ExportToExcel reportDatas={reportDatas} fileName={fileName} />
                            : ""
                        }
                    </div>
                </div>
                {/* content  */}
                <div className="my-5 mb-5 mx-7 md:mx-5 flex">
                    <div className="p-2 w-full bg-white drop-shadow-lg rounded-md flex">
                        <DataGrid
                            autoHeight {...datas}
                            rows={datas}
                            columns={columns}
                            pageSize={10}
                            getRowId={(row) => row.id}
                            rowsPerPageOptions={[10]}
                            checkboxSelection
                            disableSelectionOnClick
                            components={{ Toolbar: GridToolbar, }}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default AttendanceReport