import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import { addOfficeAddress, deleteOfficeAddress, getCities, getDistricts, getOfficeAddress, getProvinces, getStreet, getVillages } from '../../utilities/sendRequest';
import { mainOptions } from '../../utilities/selectOptions';
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Marker icon setting for leaflet
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
    iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});

const CardOfficeAddress = ({ slug, setModalData, setShowModalAddress, fetchAgain, setFetchAgain }) => {
    const [isReady, setIsReady] = useState(false)
    const [fetchMore, setFetchMore] = useState(false)

    const [typingTimeout, setTypingTimeout] = useState(null);
    const [lat, setLat] = useState(-7.2974336); // Default latitude (Surabaya
    const [lng, setLng] = useState(112.754688); // Default longitude (Surabaya
    const [markerPosition, setMarkerPosition] = useState([lat, lng]);

    const [name, setName] = useState("")
    const [postal, setPostal] = useState("")
    const [province, setProvince] = useState("")
    const [city, setCity] = useState("")
    const [district, setDistrict] = useState("")
    const [village, setVillage] = useState("")
    const [provinces, setProvinces] = useState([])
    const [cities, setCities] = useState([])
    const [districts, setDistricts] = useState([])
    const [villages, setVillages] = useState([])
    const [status, setStatus] = useState("")
    const [datas, setDatas] = useState([])

    useEffect(() => {
        fetchAddress();
        fetchProvinces();
        setFetchMore(false)
        setFetchAgain(false)
        fetchMyLocation()
        

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchMore, fetchAgain])

    useEffect(() => {
        const isReady = (name !== "" && status !== "" && postal !== "" && province !== "" && city !== "" && district !== "" && village !== "");
        setIsReady(isReady);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name, status, postal, province, city, district, village])

    useEffect(() => {
        if (province) {
            fetchCities();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [province]);

    useEffect(() => {
        if (city) {
            fetchDistricts();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [city]);

    useEffect(() => {
        if (district) {
            fetchVillages();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [district]);

    const fetchMyLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((pos) => {
                const { latitude, longitude } = pos.coords;
                
                setLat(latitude);
                setLng(longitude);
                setMarkerPosition([latitude, longitude])
            },
            (error) => {
                toast.error('Unable to retrieve your location', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000
                });
            },
            {
                enableHighAccuracy: true,  // Meminta akurasi yang lebih tinggi
                timeout: 10000,  // Timeout maksimal 10 detik
                maximumAge: 0,  // Selalu ambil data lokasi terbaru
            });
        } else {
            toast.error('Geolocation is not supported by your browser', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        }
    }

    const fetchAddress = async () => {
        getOfficeAddress(slug).then((res) => {
            setDatas(
                res.payload.sort((p1, p2) => {
                    return new Date(p2.createdAt) - new Date(p1.createdAt)
                })
            )
        }).catch((err) => {
            toast.error(err, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })  
    }

    const fetchProvinces = async () => {
        getProvinces().then((res) => {
            setProvinces(res.payload)
        }).catch((err) => {
            toast.error(err, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })  
    }

    const fetchCities = async () => {
        getCities(province).then((res) => {
            setCities(res.payload)
            setCity("")
            setDistricts([])
        }).catch((err) => {
            toast.error(err, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })  
    };

    const fetchDistricts = async () => {
        getDistricts(city).then((res) => {
            setDistricts(res.payload)
            setDistrict("")
            setVillages([])
        }).catch((err) => {
            toast.error(err, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })  
    };

    const fetchVillages = async () => {
        getVillages(district).then((res) => {
            setVillages(res.payload)
            setVillage("")
        }).catch((err) => {
            toast.error(err, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })  
    };

    const columns = [
        { 
            field: 'Address.name', 
            headerName: 'Address',
            width: 220, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {params.row.Address.name}
                    </div>
                )
            } 
        },
        {
            field: 'Address.Indonesia_Village.Indonesia_District.Indonesia_City',
            headerName: 'City',
            width: 220,
            renderCell: (params) => {
                return (
                    <div className="font-ssp">
                        {params.row.Address.Indonesia_Village.Indonesia_District.Indonesia_City.name}
                    </div>
                )
            } 
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 150,
            renderCell: (params) => {
                return (
                    <div className="font-ssp">
                        {params.row.Address.is_main
                            ? <span className="px-2 py-1 bg-green-400 rounded-full text-white uppercase">UTAMA</span>
                            : <span className="px-2 py-1 bg-gray-500 rounded-full text-white uppercase">Opsional</span>
                        }
                    </div>
                )
            } 
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 150,
            renderCell: (params) => {
                return (
                    <div className='w-full flex flex-row gap-3 items-center'>
                        <div onClick={() => handleModal(params.row)}>
                            <VisibilityIcon fontSize='medium' className="text-slate-400 hover:text-slate-500 cursor-pointer"/>
                        </div>
                        <DeleteIcon fontSize='medium' className="text-red-400 hover:text-red-500 cursor-pointer" onClick={() => handleDelete(params.row.id)}/>
                        
                    </div>
                )
            }
        },
    ]

    const handleDelete = async (paramsId) => {
        deleteOfficeAddress(paramsId).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        }).catch((err) => {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        }).finally(() => {
            setFetchMore(true)
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
    
        setIsReady(false)

        const reqData = {
            name,
            postal_code: postal,
            village_id: village,
            meta: `${lat}, ${lng}`,
            is_main: status
        }

        addOfficeAddress(slug, reqData).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
            setFetchMore(true)
            handleReset();
        }).catch((err) => {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        }).finally(() => {
            setIsReady(true)
        })
    }

    const handleReset = () => {
        setName("")
        setPostal("")
        setProvince("")
        setCity("")
        setCities([])
        setDistrict("")
        setDistricts([])
        setVillage("")
        setVillages([])
        setStatus("")
    }

    const handleModal = (data) => {
        setModalData(data)
        setShowModalAddress(true)
    }

    const LocationMarker = () => {
        useMapEvents({
            click(e) {
                const { lat, lng } = e.latlng;
                setLat(lat);
                setLng(lng);
                setMarkerPosition([lat, lng]);
            },
        });
        return markerPosition ? <Marker position={markerPosition}></Marker> : null;
    }

    const handleAddressChange = (e) => {
        const inputValue = e.target.value;
        setName(inputValue); 

        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }
      
        setTypingTimeout(setTimeout(() => {
            getStreet(inputValue)
                .then((res) => {
                    if (res.length > 0) {
                        const location = res[0];
                        const latitude = parseFloat(location.lat);
                        const longitude = parseFloat(location.lon);

                        setLat(latitude);
                        setLng(longitude);
                        setMarkerPosition([latitude, longitude]);
                    } else {
                        console.log("Location not found based on address name!");
                        // toast.error('Location not found!', {
                        //     position: toast.POSITION.TOP_RIGHT,
                        //     autoClose: 3000,
                        // });
                    }
                })
                .catch((err) => {
                    console.log("Error "+ err);
                    // toast.error(err, {
                    //     position: toast.POSITION.TOP_RIGHT,
                    //     autoClose: 3000,
                    // });
                });
            }, 500)
        );
    };

    return (
        <div className='w-full my-5 flex flex-col lg:flex-row justify-evenly gap-7 md:gap-10'>
            {/* list data */}
            <div className='w-full h-full lg:w-[60%] flex flex-col gap-3 items-center'>
                <div className="p-2 w-full flex bg-white drop-shadow-lg rounded-md">
                    <DataGrid
                        autoHeight {...datas}
                        rows={datas}
                        columns={columns}
                        pageSize={5}
                        getRowId={(row) => row.id}
                        rowsPerPageOptions={[5]}
                        checkboxSelection
                        disableSelectionOnClick
                        components={{ Toolbar: GridToolbar, }}
                    />
                </div>
            </div>

            {/* form create */}
            <div className="px-2 py-4 w-full h-fit lg:w-[40%] bg-white drop-shadow-lg rounded-md flex flex-col gap-3">
                <h1 className='font-semibold text-base font-ssp px-3'>Add Address Office</h1>
                <hr className='px-3'/>
                <div className=" py-1 w-full h-fit flex">
                    <form onSubmit={handleSubmit} className='px-5 w-full flex flex-col justify-between'>
                        {/* address */}
                        <div className="mt-3 mb-6 w-full relative z-0 group">
                            <input 
                                value={name} 
                                // onChange={(e) => setName(e.target.value)} 
                                onChange={handleAddressChange}
                                type="text" 
                                name="name"
                                id="name" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer" 
                                placeholder=" " 
                                required 
                            />
                            <label 
                                htmlFor="name" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Address</label>
                        </div>
                        {/* postal */}
                        <div className="mt-3 mb-6 w-full relative z-0 group">
                            <input 
                                value={postal} 
                                onChange={(e) => setPostal(e.target.value)} 
                                type="text" 
                                name="postal"
                                id="postal" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer" 
                                placeholder=" "  
                            />
                            <label 
                                htmlFor="postal" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Postal Code</label>
                        </div>
                        {/* provinces */}
                        <div className="mt-3 mb-6 w-full relative z-0 group">
                            <select 
                                required 
                                value={province} 
                                onChange={(e) => setProvince(e.target.value)} 
                                id="province" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer"
                            >
                                <option value="">Choose a Province</option>
                                {provinces.map((option) => (
                                    <option key={option.id} value={option.id}>{option.name}</option>
                                ))}
                            </select>
                            <label 
                                htmlFor="province" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Province</label>
                        </div>
                        {/* city */}
                        <div className="mt-3 mb-6 w-full relative z-0 group">
                            <select 
                                required 
                                value={city} 
                                onChange={(e) => setCity(e.target.value)} 
                                id="city" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer"
                            >
                                <option value="">Choose a City</option>
                                {cities.map((option) => (
                                    <option key={option.id} value={option.id}>{option.name}</option>
                                ))}
                            </select>
                            <label 
                                htmlFor="city" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >City</label>
                        </div>
                        {/* district */}
                        <div className="mt-3 mb-6 w-full relative z-0 group">
                            <select 
                                required 
                                value={district} 
                                onChange={(e) => setDistrict(e.target.value)} 
                                id="district" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer"
                            >
                                <option value="">Choose a District</option>
                                {districts.map((option) => (
                                    <option key={option.id} value={option.id}>{option.name}</option>
                                ))}
                            </select>
                            <label 
                                htmlFor="district" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >District</label>
                        </div>
                        {/* village */}
                        <div className="mt-3 mb-6 w-full relative z-0 group">
                            <select 
                                required 
                                value={village} 
                                onChange={(e) => setVillage(e.target.value)} 
                                id="village" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer"
                            >
                                <option value="">Choose a Village</option>
                                {villages.map((option) => (
                                    <option key={option.id} value={option.id}>{option.name}</option>
                                ))}
                            </select>
                            <label 
                                htmlFor="village" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Village</label>
                        </div>
                        {/* status */}
                        <div className="mt-3 mb-6 w-full relative z-0 group">
                            <select 
                                required 
                                value={status} 
                                onChange={(e) => setStatus(e.target.value)} 
                                id="status" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer"
                            >
                                {mainOptions.map((option) => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                ))}
                            </select>
                            <label 
                                htmlFor="status" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Status</label>
                        </div>
                        {/* map */}
                        <div className="mt-3 mb-6 w-full relative z-0 group" style={{ height: '400px' }}>
                            <MapContainer center={markerPosition} position={markerPosition} zoom={5} style={{ height: "100%", width: "100%" }}>
                                <TileLayer
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                />
                                <LocationMarker />
                            </MapContainer>
                        </div>
                        {/* button */}
                        <div className="mb-6 w-full relative z-0 flex justify-center gap-5">
                            <button 
                                type="submit" 
                                disabled={!isReady}
                                className={`mt-5 px-5 py-2.5 w-fit rounded-lg sm:w-autorounded-lg font-medium text-sm text-center ${isReady ? 'text-white bg-green-400 hover:bg-green-500 cursor-pointer' : 'text-gray-800 bg-gray-300 cursor-wait'}`}
                            >Add</button>
                            <button 
                                type="button" 
                                onClick={handleReset}
                                className="mt-5 px-5 py-2.5 w-fit rounded-lg sm:w-autorounded-lg font-medium text-sm text-center text-white bg-red-400 hover:bg-red-500 cursor-pointer"
                            >Reset</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

CardOfficeAddress.propTypes = {
    slug: PropTypes.string,
    setModalData: PropTypes.func,
    setShowModalAddress: PropTypes.func,
    fetchAgain: PropTypes.bool,
    setFetchAgain: PropTypes.func,
}

export default CardOfficeAddress
