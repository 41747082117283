import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { RiCloseFill } from "react-icons/ri";
import { PopupContext } from '../../context/popupContext';
import { getFilteredOvertimes, getOffices } from '../../utilities/sendRequest';

const ModalFilterOvertime = ({ setDatas, setShowModalFilter, setFetchMore, setStartTime, setEndTime, setDivision, setDivisionSlug }) => {
    const { popupDispatch } = useContext(PopupContext)
    const clickRef = useRef(null)
    const [isReady, setIsReady] = useState(false)
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [office, setOffice] = useState("")
    const [officeTitle, setOfficeTitle] = useState("All Office")
    const [offices, setOffices] = useState([])
    
    useEffect(() => {
        fetchOffices();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const isReady = (startDate !== "" && endDate !== "");
        setIsReady(isReady);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, endDate])

    const fetchOffices = async () => {
        getOffices().then((res) => {
            setOffices(res.payload.filter(office => office.is_active === true))
        }).catch((err) => {
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })  
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (clickRef.current && !clickRef.current.contains(event.target)) {
                setShowModalFilter(null)
                popupDispatch({ type: "POPUP_END" });
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clickRef])

    const handleModal = () => {
        setShowModalFilter(null)
        popupDispatch({ type: "POPUP_END" });
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        setIsReady(false)

        const startTime = `${startDate} 00:00:00`
        const endTime = `${endDate} 23:59:59`
        
        getFilteredOvertimes(office, startTime, endTime).then((res) => {
            setDatas(
                res.payload.sort((p1, p2) => {
                    return new Date(p2.createdAt) - new Date(p1.createdAt)
                })
            )
        }).catch((err) => {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        }).finally(() => {
            setStartTime(startDate)
            setEndTime(endDate)
            setDivision(officeTitle)
            setDivisionSlug(office)
            setFetchMore(true)
            setIsReady(true)
            setShowModalFilter(false)
        })
        popupDispatch({ type: "POPUP_END" });
    }

    const handleOfficeChange = (e) => {
        const selectedOfficeSlug = e.target.value;
        const selectedOfficeName = e.target.options[e.target.selectedIndex].text;
        
        setOffice(selectedOfficeSlug);
        setOfficeTitle(selectedOfficeName);
    };

    return (
        <div className="absolute top-0 left-0 z-10 p-3 w-screen min-h-screen overscroll-y-none overflow-y-hidden bg-black bg-opacity-70 flex items-center justify-center">
            <div ref={clickRef} className="p-3 w-11/12 lg:w-1/3 md:w-1/2 h-full bg-white rounded-md flex flex-col">
                <div className="px-2 flex justify-between items-center">
                    <h3 className='font-bold font-ssp text-xl md:text-2xl'>Filter Overtime</h3>
                    <RiCloseFill onClick={handleModal} className='w-8 h-8 cursor-pointer'/>
                </div>
                <form onSubmit={handleSubmit} className='mt-5 px-5 w-full flex flex-col'>
                    {/* start date */}
                    <div className="relative z-0 mb-3 mt-3 w-full group">
                        <input 
                            onChange={(e) => setStartDate(e.target.value)} 
                            value={startDate}
                            type="date" 
                            name="startDate" 
                            id="startDate" 
                            className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 
                                        appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                            placeholder=" " 
                            required
                        />
                        <label 
                            htmlFor="startDate" 
                            className="absolute top-3 -z-10 text-sm text-gray-500 dark:text-gray-400 
                                        duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:font-medium
                                        peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 
                                        peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                        >Start Date</label>
                    </div>
                    {/* end date */}
                    <div className="relative z-0 mb-3 mt-3 w-full group">
                        <input 
                            onChange={(e) => setEndDate(e.target.value)}
                            value={endDate}
                            type="date" 
                            name="endDate" 
                            id="endDate" 
                            className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 
                                        appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                            placeholder=" " 
                            required
                        />
                        <label 
                            htmlFor="endDate" 
                            className="absolute top-3 -z-10 text-sm text-gray-500 dark:text-gray-400 
                                        duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:font-medium
                                        peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 
                                        peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                        >End Date</label>
                    </div>
                    {/* office */}
                    <div className="relative z-0 mb-6 mt-3 w-full group">
                        <select 
                            value={office} 
                            onChange={handleOfficeChange} 
                            id="office_select" 
                            className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        >
                            <option value="">All Office</option>
                            {offices.map((option) => (
                                <option key={option.slug} value={option.slug}>
                                    {option.name} 
                                </option>
                            ))}
                        </select>
                        <label 
                            htmlFor="office_select" 
                            className="absolute top-3 -z-10 text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:font-medium peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                        >Office</label>
                    </div>
                    
                    {/* Button */}
                    <div className="mb-6 mt-5 w-full relative z-0 flex gap-3 justify-end">
                        <button type="submit" 
                            disabled={!isReady}
                            className={`mt-5 px-5 py-2.5 w-full sm:w-auto ${isReady ? 'text-white bg-blue-400 hover:bg-blue-500 cursor-pointer' : 'text-gray-800 bg-gray-300 cursor-wait'} rounded-lg text-sm
                                        text-center font-medium`}
                        >Submit</button>
                        <button onClick={handleModal} 
                            className="px-5 py-2.5 mt-5 w-full sm:w-auto bg-red-400 hover:bg-red-500 rounded-lg text-sm text-white
                                        text-center font-medium cursor-pointer"
                        >Close</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

ModalFilterOvertime.propTypes = {
    setDatas: PropTypes.func,
    setShowModalFilter: PropTypes.func,
    setFetchMore: PropTypes.func,
    setStartTime: PropTypes.func,
    setEndTime: PropTypes.func,
    setDivision: PropTypes.func,
}

export default ModalFilterOvertime