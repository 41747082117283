import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { RiCloseFill } from "react-icons/ri";
import { toast } from 'react-toastify';
import { PopupContext } from '../../context/popupContext';
import { attendanceTypeOptions } from '../../utilities/selectOptions';
import { createAttendance } from '../../utilities/sendRequest';

const ModalAttendanceCreate = ({ setShowModalCreate }) => {
    const { popupDispatch } = useContext(PopupContext)
    const clickRef = useRef(null)
    const [isReady, setIsReady] = useState(false)
    const [date, setDate] = useState("")
    const [clockIn, setClockIn] = useState("")
    const [clockOut, setClockOut] = useState("")
    const [type, setType] = useState("")
    const [note, setNote] = useState("")
    const [nip, setNip] = useState("")
    const [photo, setPhoto] = useState("")

    useEffect(() => {
        const isReady = (date !== "" && clockIn !== "" && type !== "" && nip !== "");
        setIsReady(isReady);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date, clockIn, type, nip])
    
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (clickRef.current && !clickRef.current.contains(event.target)) {
                setShowModalCreate(null)
                popupDispatch({ type: "POPUP_END" });
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clickRef])

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsReady(false)

        const reqData = new FormData();
        reqData.append("date", date);
        reqData.append("clock_in", clockIn);
        reqData.append("clock_out", clockOut);
        reqData.append("status", type);
        reqData.append("nip", nip);
        reqData.append("note", note);

        if(photo) {
            reqData.append("photo", photo);
        }

        createAttendance(reqData).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        }).catch((error) => {
            toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        }).finally(() => {
            setIsReady(true)
            setShowModalCreate(null)
            popupDispatch({ type: "POPUP_END" });
        })
    }

    const handleModal = () => {
        setShowModalCreate(null)
        popupDispatch({ type: "POPUP_END" });
    }

    return (
        <div className="absolute top-0 left-0 z-10 p-3 w-screen min-h-screen overscroll-y-none overflow-y-hidden bg-black bg-opacity-70 flex items-center justify-center">
            <div ref={clickRef} className="p-3 w-11/12 lg:w-1/3 md:w-1/2 h-full bg-white rounded-md flex flex-col">
                <div className="px-2 flex justify-between items-center">
                    <h3 className='font-bold font-ssp text-xl md:text-2xl'>Create Employee Attendance</h3>
                    <RiCloseFill onClick={handleModal} className='w-8 h-8 cursor-pointer'/>
                </div>
                <form onSubmit={handleSubmit} className='mt-5 px-5 w-full flex flex-col'>
                    {/* date */}
                    <div className="relative z-0 mb-3 mt-3 w-full group">
                        <input 
                            value={date} 
                            onChange={(e) => setDate(e.target.value)}
                            type="date" 
                            name="date" 
                            id="date" 
                            className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 
                                        appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                            placeholder=" " 
                            required
                        />
                        <label 
                            htmlFor="date" 
                            className="absolute top-3 -z-10 text-sm text-gray-500 dark:text-gray-400 
                                        duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:font-medium
                                        peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 
                                        peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                        >Tanggal</label>
                    </div>
                    {/* jam masuk */}
                    <div className="relative z-0 mb-3 mt-3 w-full group">
                        <input 
                            value={clockIn} 
                            onChange={(e) => setClockIn(e.target.value)}
                            type="time" 
                            name="clockIn" 
                            id="clockIn" 
                            className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 
                                        appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                            placeholder=" " 
                            required
                        />
                        <label 
                            htmlFor="clockIn" 
                            className="absolute top-3 -z-10 text-sm text-gray-500 dark:text-gray-400 
                                        duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:font-medium
                                        peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 
                                        peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                        >Jam Masuk</label>
                    </div>
                    {/* jam pulang */}
                    <div className="relative z-0 mb-3 mt-3 w-full group">
                        <input 
                            value={clockOut} 
                            onChange={(e) => setClockOut(e.target.value)}
                            type="time" 
                            name="clockOut" 
                            id="clockOut" 
                            className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 
                                        appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                            placeholder=" " 
                        />
                        <label 
                            htmlFor="clockOut" 
                            className="absolute top-3 -z-10 text-sm text-gray-500 dark:text-gray-400 
                                        duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:font-medium
                                        peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 
                                        peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                        >Jam Pulang</label>
                    </div>
                    {/* employee */}
                    <div className="relative z-0 mb-3 mt-3 w-full group">
                        <input 
                            value={nip}
                            onChange={(e) => setNip(e.target.value)} 
                            type="text" 
                            name="nip" 
                            id="nip" 
                            className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 
                                        appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                            placeholder=" " 
                            required
                        />
                        <label 
                            htmlFor="nip" 
                            className="absolute top-3 -z-10 text-sm text-gray-500 dark:text-gray-400 
                                        duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:font-medium
                                        peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 
                                        peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                        >Employee NIP</label>
                    </div>
                    {/* note */}
                    <div className="relative z-0 mb-3 mt-3 w-full group">
                        <input 
                            value={note} 
                            onChange={(e) => setNote(e.target.value)}
                            type="text" 
                            name="note" 
                            id="note" 
                            className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 
                                        appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                            placeholder=" " 
                        />
                        <label 
                            htmlFor="note" 
                            className="absolute top-3 -z-10 text-sm text-gray-500 dark:text-gray-400 
                                        duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:font-medium
                                        peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 
                                        peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                        >Note</label>
                    </div>
                    {/* type */}
                    <div className="mt-3 mb-6 w-full relative z-0 group">
                        <select 
                            required 
                            value={type} 
                            onChange={(e) => setType(e.target.value)} 
                            id="type" 
                            className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent 
                                        border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                        focus:border-blue-600 peer"
                        >
                            <option value="">Choose a status</option>
                            {attendanceTypeOptions.map((option) => (
                                <option key={option.value} value={option.value} >{option.label}</option>
                            ))}
                        </select>
                        <label 
                            htmlFor="type" 
                            className="absolute top-3 -z-10 text-sm text-gray-500 
                                        duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                        peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                        peer-focus:scale-75 peer-focus:-translate-y-6"
                        >Status</label>
                    </div>
                    <div className="mb-6 mt-3 w-full relative z-0 group">
                        <label 
                            className="block mb-2 font-ssp text-sm font-medium text-gray-500" 
                            htmlFor="file_input2"
                        >Upload Photo (Opsional)</label>
                        <input 
                            type="file" 
                            name="photo"
                            onChange={(e) => setPhoto(e.target.files[0])}
                            accept='.png, .jpg, .jpeg'
                            className="block w-full text-sm text-slate-500
                                        file:mr-4 file:py-2 file:px-4
                                        file:rounded-full file:border-0
                                        file:text-sm file:font-semibold
                                        file:bg-gray-50 file:text-gray-500
                                        hover:file:bg-gray-100"
                        />
                        <p className="mt-1 font-ssp text-xs text-gray-500 dark:text-gray-300" id="file_input2_help">PNG, JPG, JPEG (MAX. 5MB).</p>
                    </div>
                    {/* Button */}
                    <div className="mb-6 mt-5 w-full relative z-0 flex gap-3 justify-end">
                        <button type="submit" 
                            disabled={!isReady}
                            className={`mt-5 px-5 py-2.5 w-full sm:w-auto ${isReady ? 'text-white bg-blue-400 hover:bg-blue-500 cursor-pointer' : 'text-gray-800 bg-gray-300 cursor-wait'} rounded-lg text-sm
                                        text-center font-medium`}
                        >Submit</button>
                        <button onClick={handleModal} 
                            className="px-5 py-2.5 mt-5 w-full sm:w-auto bg-red-400 hover:bg-red-500 rounded-lg text-sm text-white
                                        text-center font-medium cursor-pointer"
                        >Close</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

ModalAttendanceCreate.propTypes = {
    setShowModalCreate: PropTypes.func,
}

export default ModalAttendanceCreate