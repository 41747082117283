import React from 'react';
import { Dashboard, Profile, Topbar } from '../components';
import { Routes, Route } from 'react-router-dom';
import Error404 from '../pages/Error/404';
import Error500 from '../pages/Error/500'
import Error403 from '../pages/Error/403';
import PrivateRoute from './privateRoute';
import Offices from '../pages/Offices';
import Posts from '../pages/Posts';
import CreatePost from '../pages/Posts/create';
import DetailPost from '../pages/Posts/detail';
import Echelons from '../pages/Echelons';
import Positions from '../pages/Positions';
import Employees from '../pages/Employees';
import CreateEmployee from '../pages/Employees/create';
import DetailEmployee from '../pages/Employees/detail';
import Rewards from '../pages/Rewards';
import CreateReward from '../pages/Rewards/create';
import DetailReward from '../pages/Rewards/detail';
import LogPoints from '../pages/LogPoints';
import LogRewards from '../pages/LogRewards';
import DetailOffice from '../pages/Offices/detail';
import Absents from '../pages/Absents';
import Overtimes from '../pages/Overtimes';
import Attendances from '../pages/Attendances';
import AttendanceReport from '../pages/AttendanceReport';
import MasterWeb from '../pages/MasterWeb';
import Informations from '../pages/Informations';
import DetailInformation from '../pages/Informations/detail';
import EmployementStatus from '../pages/EmployementStatus';


const ContainerPanel = ({ handleSidebar }) => {
    return (
        <div>
            <Topbar handleSidebar={handleSidebar} />
            <div className="h-full bg-grayPrimary">
                <Routes>
                    <Route path='/' element={<Dashboard />} />

                    <Route path='/offices' element={<Offices />} />  
                    <Route path='/offices/:slug' element={<DetailOffice />} />  

                    <Route path='/informations' element={<Informations />} />  
                    <Route path='/informations/:id' element={<DetailInformation />} />  

                    <Route path='/posts' element={<Posts />} />
                    <Route path='/posts/add' element={<CreatePost />} />
                    <Route path='/posts/:slug' element={<DetailPost />} />

                    <Route path='/eselons' element={<Echelons />} />
                    
                    <Route path='/employees' element={<Employees />} /> 
                    <Route path='/employees/add' element={<CreateEmployee />} /> 
                    <Route path='/employees/:nip' element={<DetailEmployee />} />

                    <Route path='/employee-status' element={<EmployementStatus />} />

                    <Route path='/positions' element={<Positions />} />
                    
                    <Route path='/rewards' element={<Rewards />} /> 
                    <Route path='/rewards/add' element={<CreateReward />} /> 
                    <Route path='/rewards/:id' element={<DetailReward />} />

                    <Route path='/points' element={<LogPoints />} /> 
                    <Route path='/log-rewards' element={<LogRewards />} /> 
                    
                    <Route path='/absents' element={<Absents />} /> 
                    <Route path='/ovt' element={<Overtimes  />} /> 
                    <Route path='/attendances' element={<Attendances />} /> 
                    <Route path='/attendances-report' element={<AttendanceReport />} /> 

                    <Route path='/utilities' element={<MasterWeb />} /> 


                    <Route element={<PrivateRoute allowedRoles={['it']} />}>
                        {/* <Route path='/bvCalculation' element={<BvCalculation />} /> 
                        <Route path='/bvCalculation/:id' element={<DetailBvCalculation />} />
                        <Route path='/testimonials' element={<Testimonials />} />
                        <Route path='/testimonials/category/:id' element={<DetailTestimonialCategory />} />
                        <Route path='/testimonials/:id' element={<DetailTestimonial />} />
                        <Route path='/contents' element={<BillContents />} />
                        <Route path='/contents/category/:id' element={<DetailBillCategory />} />
                        <Route path='/contents/:id' element={<DetailBill />} />
                        <Route path='/diseases' element={<Diseases />} /> 
                        <Route path='/diseases/:id' element={<DetailDiseases />} />
                        <Route path='/benefits' element={<Benefits />} /> 
                        <Route path='/benefits/:id' element={<DetailBenefits />} />
                        <Route path='/success' element={<SuccessProfiles />} />
                        <Route path='/success/category/:id' element={<DetailProfileCategory />} />
                        <Route path='/success/:id' element={<DetailProfile />} />

                        <Route path='/audios/:slug' element={<AudioContents />} />
                        <Route path='/enterprisers/:slug' element={<Enterprisers />} />
                        <Route path='/enterprisers/detail/:id' element={<DetailEnterpriser />} />

                        <Route path='/carousels' element={<Carousels />} /> 
                        <Route path='/carousels/:id' element={<DetailCarousel />} /> */}
                    </Route>

                    <Route element={<PrivateRoute allowedRoles={['it', 'event']} />}>
                        {/* <Route path='/speakers' element={<Speakers />} />
                        <Route path='/speakers/:id' element={<DetailSpeaker />} />
                        <Route path='/nationals' element={<Nationals />} />
                        <Route path='/nationals/:uuid' element={<DetailNational />} />
                        <Route path='/dailys' element={<Dailys />} />
                        <Route path='/dailys/:uuid' element={<DetailDaily />} /> */}
                    </Route>

                    <Route element={<PrivateRoute allowedRoles={['it', 'market']} />}>
                        {/* <Route path='/productsCategories' element={<ProductCategories />} /> 
                        <Route path='/productsCategories/:id' element={<DetailProductCategory />} />
                        <Route path='/products' element={<Products />} /> 
                        <Route path='/products/:id' element={<DetailProduct />} />
                        <Route path='/marketPlaces' element={<MarketPlaces />} />
                        <Route path='/transactions' element={<Transactions />} />
                        <Route path='/transactions/detail/:id' element={<DetailTransaction />} />
                        <Route path='/reports' element={<TransactionReport />} /> */}
                    </Route>

                    {/* <Route path='/broadcasts' element={<Broadcasts />} /> 
                    <Route path='/broadcasts/:id' element={<DetailBroadcast />} /> */}
                    
                    <Route path='/profile' element={<Profile />} />
                    
                    <Route path='/*' element={<Error404 />} />
                    <Route path='/500' element={<Error500 />} />
                    <Route path='/403' element={<Error403 />} />
                </Routes>
            </div>
        </div>
    )
}

export default ContainerPanel