import React, { useEffect, useState } from 'react';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { format } from 'date-fns';
import { dateIndo } from '../../utilities/helper';
import ModalFilterAttendance from '../../components/Attendances/modalFilterAttendance';
import ModalAttendanceDetail from '../../components/Attendances/modalDetailAttendance';
import ModalAttendanceCreate from '../../components/Attendances/modalCreateAttendance';
import ExportToExcel from '../../components/Exports/ExportToExcel';

const Attendances = () => {
    const [datas, setDatas] = useState([])
    const [data, setData] = useState([])
    const [showModalDetail, setShowModalDetail] = useState(false)
    const [showModalCreate, setShowModalCreate] = useState(false)
    const [showModalFilter, setShowModalFilter] = useState(false)
    const [fetchMore, setFetchMore] = useState(true)
    const [startTime, setStartTime] = useState("")
    const [endTime, setEndTime] = useState("")
    const [divisionSlug, setDivisionSlug] = useState("")
    const [employee, setEmployee] = useState("")
    const [status, setStatus] = useState("")
    const [statusTitle, setStatusTitle] = useState("")
    const [division, setDivision] = useState("")
	const fileName = "Report_Attendances_" + format(new Date(), "yyyy-MM-dd_HH:mm:ss");
    
    useEffect(() => {
        setFetchMore(false)
        
        // eslint-disable-next-line
    }, [fetchMore])

    const columns = [
        { 
            field: 'date', 
            headerName: 'Tanggal',
            width: 150, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {dateIndo(params.row.date)}
                    </div>
                )
            } 
        },
        { 
            field: 'User.fullname', 
            headerName: 'Pegawai',
            width: 250, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {params.row.User.nip} - {params.row.User.fullname}
                    </div>
                )
            } 
        },
        { 
            field: 'User.Biodata.Echelon.title', 
            headerName: 'Eselon',
            width: 80, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {params.row.User.Biodata.Echelon.title}
                    </div>
                )
            } 
        },
        { 
            field: 'User.Biodata.Office.name', 
            headerName: 'Divisi',
            width: 200, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {params.row.User.Biodata.Office.name}
                    </div>
                )
            } 
        },
        { 
            field: 'clock_in', 
            headerName: 'Jam Masuk',
            width: 150, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {params.row.clock_in}
                    </div>
                )
            } 
        },
        { 
            field: 'clock_out', 
            headerName: 'Jam Keluar',
            width: 150, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {params.row.clock_out ? params.row.clock_out : "-"}
                    </div>
                )
            } 
        },
        { 
            field: 'status', 
            headerName: 'Status',
            width: 150, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp uppercase'>
                        {params.row.status}
                    </div>
                )
            } 
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 130,
            renderCell: (params) => {
                return (
                    <div className='w-full flex flex-row items-center gap-3'>
                        <VisibilityIcon fontSize='medium' className="text-slate-400 hover:text-slate-500 cursor-pointer" onClick={() => handleDetail(params.row)}/>
                    </div>
                )
            }
        },
    ]

    const handleDetail = (tempData) => {
        setShowModalDetail(true)
        setData(tempData)
    }

    const reportDatas = datas.map(item => (
		{ 
			"Tanggal": dateIndo(item.date), 
			"Nama Lengkap": item.User.fullname, 
			"NIP": item.User.nip, 
			"Divisi": item.User.Biodata.Office.name, 
			"Jam Masuk": item.clock_in, 
            "Lokasi Masuk": item.location_in,
			"Jam Pulang": item.clock_out, 
            "Lokasi Pulang": item.location_out,
			"Note Kehadiran": item.note, 
			"Status": item.status,
			"Note HRD": '',
		}
	));

    return (
        <>
            {showModalFilter && 
                <ModalFilterAttendance setShowModalFilter={setShowModalFilter} setDatas={setDatas} setFetchMore={setFetchMore} setStartTime={setStartTime} setEndTime={setEndTime} setDivision={setDivision} setDivisionSlug={setDivisionSlug} setEmployee={setEmployee} setStatus={setStatus} setStatusTitle={setStatusTitle} />
            }
            {showModalDetail &&
                <ModalAttendanceDetail setShowModalDetail={setShowModalDetail} data={data} setDatas={setDatas} startTime={startTime} endTime={endTime} divisionSlug={divisionSlug} employee={employee} status={status}/>
            }
            {showModalCreate &&
                <ModalAttendanceCreate setShowModalCreate={setShowModalCreate}/>
            }

            <div className={`min-h-[calc(100vh-4rem)] flex flex-col ${showModalFilter && 'fixed min-w-[calc(100vw-16.666667%)]'}`}>
                {/* header  */}
                <div className="my-5 mb-5 mx-7 md:mx-5 flex flex-col md:flex-row justify-between items-start">
                    <div className=' flex flex-col'>
                        <h1 className='font-ssp font-bold text-2xl md:text-3xl'>Attendances</h1>
                        {startTime 
                            ?
                                <span className='font-ssp font-normal text-sm md:text-xl'>{dateIndo(startTime)} - {dateIndo(endTime)}</span>
                            : ''
                        }
                        {division && statusTitle
                            ? <span className='font-ssp font-normal text-sm md:text-xl'>{division} | {employee ? employee : "All Employee"} | {statusTitle}</span> 
                            : <span className='font-ssp font-light text-sm md:text-base tracking-wide'>Lakukan filter tanggal dulu untuk menampilkan data</span>
                        }
                    </div>
                    <div className='flex flex-row flex-wrap gap-2'>
                        <div onClick={(e) => setShowModalFilter(true)} className="px-5 py-2.5 mr-3 mt-3 md:mt-1 bg-green-400 hover:bg-green-500 rounded-lg flex text-sm font-medium self-start md:self-end cursor-pointer">
                            <span className='text-sm text-white font-ssp'>Filter By Date</span>
                        </div>
                        <div onClick={(e) => setShowModalCreate(true)} className="px-5 py-2.5 mr-3 mt-3 md:mt-1 bg-blue-400 hover:bg-blue-500 rounded-lg flex text-sm font-medium self-start md:self-end cursor-pointer">
                            <span className='text-sm text-white font-ssp'>Add New</span>
                        </div>
                        {division && statusTitle
                            ?
                                <ExportToExcel reportDatas={reportDatas} fileName={fileName} />
                            : ""
                        }
                    </div>
                </div>
                {/* content  */}
                <div className="my-5 mb-5 mx-7 md:mx-5 flex">
                    <div className="p-2 w-full bg-white drop-shadow-lg rounded-md flex">
                        <DataGrid
                            autoHeight {...datas}
                            rows={datas}
                            columns={columns}
                            pageSize={10}
                            getRowId={(row) => row.id}
                            rowsPerPageOptions={[10]}
                            checkboxSelection
                            disableSelectionOnClick
                            components={{ Toolbar: GridToolbar, }}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Attendances